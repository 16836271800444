import React, { useEffect, useState } from "react";
import { useMyContext } from "../../app.context";
import { deletesingleCard, getAllId } from "../shared/api";
import { ToastError, ToastSuccess } from "../shared/SmallComponents";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";

const FileList = () => {
  const navigate = useNavigate();
  const {
    imgDownloaded,
    setImgDownloaded,
    imgDownloadedS,
    setImgDownloadedS,
    user,
    setUser,
    text,
    setText,
    image,
    setImage,
    signature,
    setSignature,
    nameBangla,
    setNameBangla,
    nameEnglish,
    setNameEnglish,
    nid,
    setNid,
    pin,
    setPin,
    nameFather,
    setNameFather,
    nameMother,
    setNameMother,
    birthPlace,
    setBirthPlace,
    dob,
    setDob,
    homeHolding,
    setHomeHolding,
    road,
    setRoad,
    postOffice,
    setPostOffice,
    postalCode,
    setPostalCode,
    upozilla,
    setUpozilla,
    city,
    setCity,
    fulladdress,
    setFulladdress,
    bloodGroup,
    setBloodGroup,
    fullNidData,
    setFullNidData,
    currentAddress,
    setCurrentAddress,
    permanentAddress,
    setPermanentAddress,
    voterNumber,
    setVoterNumber,
    formNumber,
    setFormNumber,
    spouseName,
    setSpouseName,
    pa,
    setPa,
    ca,
    setCa,
    gender,
    setGender,
    edu,
    setEdu,
    setMob,
    setOccupation,
    setReligion,
    setReligionOther 
  } = useMyContext();
  const [idCards, setIdCards] = useState([]);
  const [cardsRefresh, setCardRefresh] = useState(false);
  const [filteredData, setFilteredData] = useState({
    nameBangla:"",
    nid:"",
    pin:""
  });
  const [fil, setFil]=useState([])

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        const email = {
          email: user.email,
        };
        try {
          const userRes = await getAllId(email);
          setIdCards(userRes);
          setFil(userRes);
          // setFilteredData(userRes)
        } catch (error) {
          console.log(error);
          ToastError("error :", error?.message);
        }
      }
    };

    fetchData();
  }, [user, cardsRefresh]);

  const [allData, setAllData] = useState([
    // Assuming you have your data here
    { nameBangla: "আলী", nid: "123456789", pin: "987654", nameEnglish: "Ali" },
    { nameBangla: "ববু", nid: "987654321", pin: "456789", nameEnglish: "Babu" },
    {
      nameBangla: "মীনা",
      nid: "456123789",
      pin: "654987",
      nameEnglish: "Mina",
    },
    // More data objects...
  ]);



  const applyFilters = (filters) => {
    console.log("fite", filters);
    const filtered = idCards.filter((item) => {
      return (
        (filters.nameBangla === "" ||
          item.nameBangla
            .toLowerCase()
            .includes(filters.nameBangla?.toLowerCase())) &&
        (filters.nid === "" || item.nid?.includes(filters?.nid)) &&
        (filters.pin === "" || item.pin?.includes(filters?.pin))
        //  &&
        // (filters.nameEnglish === "" ||
        //   item.nameEnglish
        //     .toLowerCase()
        //     .includes(filters.nameEnglish.toLowerCase()))
      );
    });
    setFil(filtered);
  };
  console.log("fil", fil );

  const deleteCard = async (id) => {
    const body = {
      email: user.email,
    };
    try {
      const deleteRes = await deletesingleCard(id, body);

      if (deleteRes) {
        console.log("res", deleteRes);
        ToastSuccess("Successfully deleted");
        setCardRefresh(!cardsRefresh);
      }
    } catch (error) {
      console.log("error");
      ToastError(error?.message || "Something error");
    }
  };

  const usedata = (c) => {
    console.log("c", c);
    setBirthPlace(c?.birthPlace);
    setBloodGroup(c?.bloodGroup);
    setDob(c?.dob);
    setEdu(c?.edu);
    setGender(c?.gender);
    setImage(c?.image);
    setNameBangla(c?.nameBangla);
    setNameEnglish(c?.nameEnglish);
    setNameFather(c?.nameFather);
    setNameMother(c?.nameMother);
    setNid(c?.nid);
    setPin(c?.pin);
    setSignature(c?.signature);
    setCa(c?.ca);
    setPa(c?.pa);
    setMob(c?.mob)
    setReligion(c?.religion)
    setReligionOther(c?.religionOther)
    setOccupation(c?.occupation)


    navigate("/");
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    // transform: 'translate(-50%, -50%)',
    zIndex: 40000,

    // backgroundColor: 'white',
    // padding: '20px',
    // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
  };

  return (
    <>
      {/* <div
style={modalStyle}
       >
        {" "}
        <ReactLoading

          type={"bars"}
          color={"blue"}
          height={"10%"}
          width={"10%"}
        />{" "}
      </div> */}
      <div className="ts-main-content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                {/* <h2
                  className="page-title"
                  style={{
                    textAlign: "start !important",
                  }}
                >
                  Logs
                </h2> */}
                {/* Zero Configuration Table */}
                <div className="panel panel-default">
                  <div className="panel-heading">List Users</div>

                  {/* filter  */}
                  <form className="flex mx-2 gap-3 justify-between   mt-3  " onSubmit={(e) => e.preventDefault()}>
                    {/* <div className="text-left ">
                      <label className=" " htmlFor="nameBangla">Search by Bangla Name:</label> <br />
                      <input
                      style={{backgroundColor:"#E7E7FF ", borderRadius:"5px", height:"2rem" ,padding:"5px" , width:"100%"  }}
                        type="text"
                        id="nameBangla"
                        name="nameBangla"
                        onChange={(e) =>
                          applyFilters({
                            ...filteredData,
                            nameBangla: e.target.value,
                          })
                        }
                      />
                    </div> */}
                    <div className="text-left ">
                      <label htmlFor="nid">Search by Bangla Name:</label>
                      <input
                        type="text"
                        style={{backgroundColor:"#E7E7FF ", borderRadius:"5px", height:"2rem" ,padding:"5px" , width:"100%"   }}

                        id="nameBangla"
                        name="nameBangla"
                        onChange={(e) =>
                          applyFilters({
                            ...filteredData,
                            nameBangla: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="text-left ">
                      <label htmlFor="nid">Search by NID:</label>
                      <input
                        type="text"
                        style={{backgroundColor:"#E7E7FF ", borderRadius:"5px", height:"2rem" ,padding:"5px" , width:"100%"   }}

                        id="nid"
                        name="nid"
                        onChange={(e) =>
                          applyFilters({ ...filteredData, nid: e.target.value })
                        }
                      />
                    </div>
                    <div className="text-left ">
                      <label htmlFor="pin">Search by PIN:</label>
                      <input
                        type="text"
                        style={{backgroundColor:"#E7E7FF ", borderRadius:"5px", height:"2rem" ,padding:"5px", width:"100%"    }}
                        id="pin"
                        name="pin"
                        onChange={(e) =>
                          applyFilters({ ...filteredData, pin: e.target.value })
                        }
                      />
                    </div>
                    {/* <div className="text-left ">
                      <label htmlFor="nameEnglish">
                        Search by English Name:
                      </label>
                      <input
                        type="text"
                        style={{backgroundColor:"#E7E7FF ", borderRadius:"5px", height:"2rem" ,padding:"5px"   }}
                        id="nameEnglish"
                        name="nameEnglish"
                        onChange={(e) =>
                          applyFilters({
                            ...filteredData,
                            nameEnglish: e.target.value,
                          })
                        }
                      />
                    </div> */}
                  </form>

                  {/* filter  */}
                  <div className="panel-body">
                    <table
                      id="zctb"
                      className="display table table-striped table-bordered table-hover"
                      cellSpacing={0}
                      width="100%"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>NameBangla</th>
                          <th>nid</th>
                          <th>Pin</th>
                          <th>Father Name</th>
                          <th>Use</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fil?.map((card, index) => {
                          // console.log("ima", card?.image);
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              {/* <td><img src="../images/" style="width:50px; border-radius:50%;" /></td> */}
                              <td>
                                <div className="d-flex gap-1">
                                  {/* <img src={image}   style={{borderRadius:"50%"}} alt="Image" /> */}
                                  <div>{card?.nameBangla}</div>
                                </div>
                              </td>
                              <td>{card?.nid}</td>
                              <td>{card?.pin}</td>
                              <td>{card?.nameFather}</td>
                              <td>
                                <button onClick={() => usedata(card)}>
                                  Use
                                </button>
                              </td>
                              <td>
                                {" "}
                                <button onClick={() => deleteCard(card._id)}>
                                  <i
                                    className="fa fa-trash"
                                    style={{ color: "red" }}
                                    aria-hidden="true"
                                  />
                                </button>
                              </td>
                              {/* </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileList;
