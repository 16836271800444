import React from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';

const Navigation =[
  {
    id:1,
    name:'Create File',
    icon:"menu-icon tf-icons fas fa-address-card",
    link:'/',
    buttonClass:'btn rounded-pill btn-primary'
  },{
    id:2,
    name:'File List',
    icon:"menu-icon fas fa-list",
    link:'/fileList',
    buttonClass:'btn rounded-pill btn-secondary'
  }
  ,{
    id:2,
    name:'Voter List',
    icon:"menu-icon fas fa-list",
    link:'/voterList',
    buttonClass:'btn rounded-pill btn-secondary'
  },{
    id:3,
    name:'Profile',
    icon:"menu-icon fas fa-user",
    link:'/profile',
    buttonClass:'btn rounded-pill btn-secondary'
  },
  // {
  //   id:4,
  //   name:'Change Password',
  //   icon:"menu-icon fas fa-unlock-alt",
  //   link:'/changePassword',
  //   buttonClass:'btn rounded-pill btn-primary'
  // },
  {
    id:5,
    name:'Users',
    icon:"menu-icon fas fa-sign-out-alt",
    link:'/users',
    buttonClass:'btn rounded-pill btn-secondary'
  },
  {
    id:6,
    name:'Log Out',
    icon:"menu-icon fas fa-sign-out-alt",
    link:'/login',
    buttonClass:'btn rounded-pill btn-danger'
  }
]

const Dashboard = () => {
  const [active,setActive] = React.useState(1)
  const navigate=useNavigate()

  const logout =()=>{
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    navigate("/login")
  }
    return (
        <div className='layout-wrapper layout-content-navbar'>
            <div className='layout-container'>
                {/* ------menu------- */}
            <aside
  id="layout-menu"
  className="layout-menu menu-vertical menu bg-menu-theme"
>
  <div className="app-brand demo">
    <Link to='/' className="app-brand-link">
      <span className="app-brand-logo demo">
        <img width="50px" src="assets/img/logo.png" />
      </span>
      <span className="app-brand-text  menu-text fw-bolder ms-2">
        ONLINE SEBA
      </span>
    </Link>

  </div>
  <div className="menu-inner-shadow" />
  <ul className="menu-inner py-1">
    {/* Dashboard */}
    {
      Navigation.map((item,index)=>(
        item.name ==="Log Out"?
        <li key={index} className={
          active === item.id ? 'menu-item active' : 'menu-item'
        }
        onClick={()=>setActive(item.id)}
        >
          <Link to={item.link} onClick={logout} className="menu-link">
          <i className={item.icon} />
          <div data-i18n="Analytics" style={{color:"black !important"}}>{item.name}</div>
        </Link>
      </li>
      :
        <li key={index} className={
          active === item.id ? 'menu-item active' : 'menu-item'
        }
        onClick={()=>setActive(item.id)}
        >
          <Link to={item.link} className="menu-link">
          <i className={item.icon} />
          <div data-i18n="Analytics" style={{color:"black !important"}}>{item.name}</div>
        </Link>
      </li>
      ))
    }

{/* <Link key={index} onClick={logout} to={item.link}>
                      <button
                        type="button"
                        className={item.buttonClass}
                      >
                        {item.name}
                      </button>
                    </Link> */}

    {/* Layouts */}
    {/* Components
            <li className="menu-header small text-uppercase"><span className="menu-header-text">Components</span></li>
            <!-- Cards */}
    {/* Extended components */}
  </ul>
           </aside>
{/* -----menu---- */}
{/* -----page----- */}
<div className="layout-page">
  {/* Navbar */}
  <nav
    className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
    id="layout-navbar"
  >
    <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
      <a className="nav-item nav-link px-0 me-xl-4" href="#">
        <i className="bx bx-menu bx-sm" />
      </a>
    </div>
    <div
      className="navbar-nav-right d-flex align-items-center"
      id="navbar-collapse"
    >
      {/* Search */}
      <div className="navbar-nav align-items-center">
        <div className="nav-item d-flex align-items-center">
          <br />
          <h4 style={{ marginBottom: 0 }}>Welcome </h4>
        </div>
      </div>
      {/* /Search */}
      <ul className="navbar-nav flex-row align-items-center ms-auto">

        {/* <button type="button" className="btn btn-primary" style={{color:"black"}} >
          {" "}
          টাকা : 631
        </button> */}

        <li className="nav-item navbar-dropdown dropdown-user dropdown">
          <a
            className="nav-link dropdown-toggle hide-arrow"
            href="#"
            data-bs-toggle="dropdown"
          >
            <div className="avatar avatar-online">
              {/* <img
                src="assets/img/avatars/1.png"
                alt=""
                className="w-px-40 h-auto rounded-circle"
              /> */}
            </div>
          </a>
          <ul className="dropdown-menu dropdown-menu-end">
            <li>
              <a className="dropdown-item" href="#">
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar avatar-online">
                      {/* <img
                        src="assets/img/avatars/1.png"
                        alt=""
                        className="w-px-40 h-auto rounded-circle"
                      /> */} 
                    </div>
                  </div>
                  <div className="flex-grow-1"></div>
                </div>
              </a>
            </li>
            <li>
              <div className="dropdown-divider" />
            </li>
            <li>
              {/* <a className="dropdown-item" href="logout.php">
                <i className="bx bx-power-off me-2" />
                <span className="align-middle">Log Out</span>
              </a> */}
              {/* <button> <i className="bx bx-power-off me-2" />
                <span className="align-middle">Log test Out</span></button> */}
            </li>
          </ul>
        </li>
        {/*/ User */}
      </ul>
    </div>
  </nav>
  {/* / Navbar */}
  {/* Content wrapper */}
  <div className="content-wrapper">
    {/* Content */}
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col-lg-12 mb-4 order-0">
          <div className="card">
            <div className="col-sm-12">
              <div className="card-body">
                <div className="button_flex">
                  {
                    Navigation.map((item,index)=>(
                      item.name=="Log Out" ?
                      <Link key={index} onClick={logout} style={{color:"black !important"}} to={item.link}>
                      <button
                        type="button"
                        className={item.buttonClass}
                        style={{color:"black"}}
                      >
                        {item.name}
                      </button>
                    </Link>
                      :
                      <Link key={index} to={item.link}>
                      <button
                        type="button"
                        className={item.buttonClass}
                        style={{color:"black"}}
                      >
                        {item.name}
                      </button>
                    </Link>
                    ))
                  }
                  {/* Button trigger modal */}
                </div>
              </div>

               {/* ----main content------- */}
               <Outlet/>
               {/* ----main content------- */}


              {/* Loading Scripts */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* -----page----- */}
            </div>
        </div>
    );
};

export default Dashboard;
