import React, { useEffect, useState } from "react";
import imgS from "../../assets/sign.jpg";
import "./verify.css";
import { ToastError, ToastSuccess } from "../shared/SmallComponents";
import img1 from "../../assets/Screenshot_118.png";
import img2 from "../../assets/Screenshot_119.png";
import img3 from "../../assets/Ec.png";
import { useMyContext } from "../../app.context";

const Verification = () => {
  const {
    imgDownloaded,
    setImgDownloaded,
    imgDownloadedS,
    setImgDownloadedS,
    user,
    setUser,
    text,
    setText,
    image,
    setImage,
    signature,
    setSignature,
    nameBangla,
    setNameBangla,
    nameEnglish,
    setNameEnglish,
    nid,
    setNid,
    pin,
    setPin,
    nameFather,
    setNameFather,
    nameMother,
    setNameMother,
    dob,
    setDob,
    homeHolding,
    setHomeHolding,
    road,
    setRoad,
    postOffice,
    setPostOffice,
    postalCode,
    setPostalCode,
    upozilla,
    setUpozilla,
    city,
    setCity,
    fulladdress,
    setFulladdress,
    bloodGroup,
    setBloodGroup,
    fullNidData,
    setFullNidData,
    currentAddress,
    setCurrentAddress,
    permanentAddress,
    setPermanentAddress,
    voterNumber,
    setVoterNumber,
    formNumber,
    setFormNumber,
    spouseName,
    setSpouseName,
    pa,
    setPa,
    ca,
    setCa,
    edu,
    setEdu,
    gender,
    setGender,
    birthPlace,
    setBirthPlace,
    serverA,
    setServerA,
    nidA,
    setNidA,
    serverPresent,
    mob,
    setMob,
    religion,
    setReligion,
    religionOther,
    setReligionOther,
    occupation,
    setOccupation,
  } = useMyContext();

  const [nameModify, setNameModify] = useState();
  const [dobModify, setDobModify] = useState();
  // console.log("cu", currentAddress);
  // console.log("per", permanentAddress);

  // useEffect(()=>{

  //   const getReadyName=(cName)=>{
  //     if(cName !==(null || undefined)){
  //       if (cName.indexOf(' ') !== -1) {
  //         var modifiedName = cName.split(' ').join('+');
  //         console.log(modifiedName);
  //         return modifiedName ;
  //       } else {
  //         console.log("No space found in the string.", cName);
  //         return cName ;
  //       }

  //     }
  //     else {
  //       console.log("Null ", cName);
  //       return cName ;
  //     }

  //   }

  //   setNameModify(getReadyName(nameEnglish))
  //   setDobModify(getReadyName(dob))

  // },[nameEnglish,dob])

  useEffect(() => {
    const formattedName = nameEnglish?.split(" ")?.join("+");
    setNameModify(formattedName);

    const formattedDOB = formatDOB(dob);
    setDobModify(formattedDOB);
  }, [dob, nameEnglish]);

  function formatDOB(inputDOB) {
    if (inputDOB == null) {
      return inputDOB;
    }
    // Split the input date into day, month, and year
    const parts = inputDOB.split(" ");
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    // Define a mapping of month names to month numbers
    const monthMap = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    // Convert the month name to a month number
    const formattedMonth = monthMap[month];

    // Create the formatted date string
    const formattedDOB = `${year}-${formattedMonth}-${day}`;

    return formattedDOB;
  }

  // console.log("nameEgnlish", nameModify, dobModify  );

  useEffect(() => {
    // window.print();
  }, []);

  return (
    <div className="bg-blue-200 serverPage">
      <div className="bg-white serverPage mt-8"  style={{ width: "1010px", margin: " 0 auto", overflow: "hidden" }}>
        <div className="mainPage px-4  ">
          <div className="topsection ">
           <div className="imgDivVerify">
           <img src={img3} className="" alt="gov" />
           </div>
            <div className="text-center headingDiv ">
              <h4 className="verifymax blackText2   mb-2   ">
                বাংলাদেশ নির্বাচন কমিশন
              </h4>
              <h5 className="verifysemi   blackText2  mb-2">
                জাতীয় পরিচয় নিবন্ধন অনুবিভাগ
              </h5>
              <h5 className="verifysemi   blackText2  ">
                জাতীয় পরিচয়পত্র অনলাইন যাচাই
              </h5>
            </div>
          </div>

          <div className=" hr hrVerification"></div>
          <section className=" bottomsection">
            <div className=" imgdiv">
              <img src={image} className="w-full personImg " alt="user" />
              {/* <img src={img1} className="w-full h-auto" alt="user" /> */}
              <div className="propName whitespace-nowrap ">{nameEnglish}</div>

              {/* <img
                alt="Barcode Generator TEC-IT"
                src={`https://barcode.tec-it.com/barcode.ashx?data=%3Cpin%3E${pin}%3C%2Fpin%3E%3Cname%3E${nameIs}%3C%2Fname%3E%3CDOB%3E15+Jan+2001%3C%2FDOB%3E%3CFP%3E%3C%2FFP%3E%3CF%3ERight+Index%3C%2FF%3E%3CTYPE%3EA%3C%2FTYPE%3E%3CV%3E2.0%3C%2FV%3E%3Cds%3E302c02143b7535d0ac3f220161b85f8966ab989312dc5c290214535d0f0652482e2e2a04842aa54a4c32cc7624dd%3C%2Fds%3E&code=PDF417`}
              /> */}

              <img
                style={{ width: "68%", margin: "auto", marginTop: "25px" }}
                className="barcodeImgVerify"
                alt="qr code"
                src={`https://barcode.tec-it.com/barcode.ashx?data=${nameModify}+6884685154+${dobModify}&code=QRCode&eclevel=L`}
              />
            </div>
            <div className="infoDiv bodyDiv   ">
              <div className="dataInDiv dataInDiv2 flex w-full text-left   ">
                <div className=" names2 titleText bold headingName">
                  জাতীয় পরিচিতি
                </div>
                <div className=" details "></div>
              </div>
              <div className="dataInDiv flex w-full text-left   ">
                <div className=" names2  verificationNormalText  ">
                  জাতীয় পরিচয় পত্র নম্বর
                </div>
                <div className=" details verificationNormalText  ">{nid}</div>
              </div>
              <div className="dataInDiv flex w-full text-left   ">
                <div className=" names2  verificationNormalText ">
                  পিন নম্বর
                </div>
                <div className=" details verificationNormalText  ">{pin}</div>
              </div>
              <div className="dataInDiv flex w-full text-left   ">
                <div className=" names2 verificationNormalText  ">
                  ফরম নম্বর
                </div>
                <div className=" details verificationNormalText  ">
                  {formNumber}
                </div>
              </div>
              <div className="dataInDiv flex w-full text-left   ">
                <div className=" names2  verificationNormalText ">
                  ভোটার নম্বর
                </div>
                <div className=" details verificationNormalText  ">
                  {voterNumber}
                </div>
              </div>
              <div className="dataInDiv flex w-full text-left   ">
                <div className=" names2 verificationNormalText  ">
                  ভোটার এলাকা
                </div>
                <div className=" details verificationNormalText  ">
                  {pa?.VillageRoad || pa?.AdditionalVillageRoad}{" "}
                </div>
                {/* ( {pa?.WardForUnionPorishod} নং ওয়ার্ড ) */}
              </div>
              <div className="dataInDiv dataInDiv2 flex w-full text-left   ">
                <div className=" names2 bold headingName">ব্যক্তিগত তথ্য</div>
                <div className=" details "></div>
              </div>
              <div className="dataInDiv flex w-full text-left   ">
                <div className=" names2  verificationNormalText ">
                  নাম (বাংলা)
                </div>
                <div className=" details verificationNormalText  ">
                  {nameBangla}
                </div>
              </div>
              <div className="dataInDiv flex w-full text-left   ">
                <div className=" names2  verificationNormalText ">
                  নাম (ইংরেজী)
                </div>
                <div className=" details  verificationNormalText ">
                  {nameEnglish}
                </div>
              </div>
              <div className="dataInDiv flex w-full text-left   ">
                <div className=" names2  verificationNormalText ">
                  জন্ম তারিখ
                </div>
                <div className=" details verificationNormalText  ">{dob}</div>
              </div>
              <div className="dataInDiv flex w-full text-left   ">
                <div className=" names2  verificationNormalText ">
                  পিতার নাম
                </div>
                <div className=" details  verificationNormalText ">
                  {nameFather}
                </div>
              </div>
              <div className="dataInDiv flex w-full text-left   ">
                <div className=" names2  verificationNormalText ">
                  মাতার নাম
                </div>
                <div className=" details  verificationNormalText ">
                  {nameMother}
                </div>
              </div>
              <div className="dataInDiv flex w-full text-left   ">
                <div className=" names2 verificationNormalText  ">
                  স্বামী / স্ত্রীর নাম
                </div>
                <div className=" details verificationNormalText  ">
                  {spouseName}
                </div>
              </div>
              <div className="dataInDiv dataInDiv2 flex w-full text-left   ">
                <div className=" names2  headingName  bold">অন্যন্য তথ্য</div>
                <div className=" details "></div>
              </div>

              <div className="dataInDiv flex w-full text-left   ">
                <div className=" names2 verificationNormalText  ">
                  জন্ম স্থান
                </div>
                <div className=" details verificationNormalText  ">
                  {birthPlace}
                </div>
              </div>
              <div className="dataInDiv flex w-full text-left   ">
                <div className=" names2  verificationNormalText ">লিঙ্গ</div>
                <div className=" details verificationNormalText  ">
                  {gender}
                </div>
              </div>
              {edu && (
                <div className="dataInDiv flex w-full text-left   ">
                  <div className=" names2 verificationNormalText  ">
                    শিক্ষাগত যোগ্যতা
                  </div>
                  <div className=" details  verificationNormalText ">{edu}</div>
                </div>
              )}
              {bloodGroup && (
                <div className="dataInDiv flex w-full text-left   ">
                  <div className=" names2  verificationNormalText ">
                    রক্তের গ্রুপ
                  </div>
                  <div className=" details  verificationNormalText ">
                    {bloodGroup}
                  </div>
                </div>
              )}
              {occupation && (
                <div className="dataInDiv flex w-full text-left   ">
                  <div className=" names2  verificationNormalText ">পেশা</div>
                  <div className=" details verificationNormalText  ">
                    {occupation}
                  </div>
                </div>
              )}
              {(religion || religionOther) && (
                <div className="dataInDiv flex w-full text-left   ">
                  <div className=" names2  verificationNormalText ">ধর্ম</div>
                  <div className=" details  verificationNormalText ">
                    {religion || religionOther}
                  </div>
                </div>
              )}
              {mob && (
                <div className="dataInDiv flex w-full text-left   ">
                  <div className=" names2 verificationNormalText  ">মোবাইল</div>
                  <div className=" details verificationNormalText  ">{mob}</div>
                </div>
              )}
              <div className="dataInDiv dataInDiv2 flex w-full text-left   ">
                <div className=" names2 bold headingName">বর্তমান ঠিকানা</div>
                <div className=" details "></div>
              </div>
              <div className="dataInDiv  flex w-full text-left   ">
                <div
                  className="names3 verificationNormalText "
                  style={{ lineHeight: "30px" }}
                >
                  {serverPresent}
                </div>
              </div>
              <div className="dataInDiv dataInDiv2 flex w-full text-left   ">
                <div className=" names2 bold headingName">স্থায়ী ঠিকানা</div>
                <div className=" details "></div>
              </div>
              <div className="dataInDiv flex w-full text-left   ">
                <div
                  className="names3 verificationNormalText "
                  style={{ lineHeight: "30px" }}
                >
                  {serverA}
                  <br />
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="aletSection">
          <p className="alertingText">
            উপরে প্রদর্শিত তথ্যসমূহ জাতীয় পরিচয়পত্র সংশ্লিষ্ট, ভোটার রালিকার
            সাথে সরাসরি সম্পর্কযুক্ত নয় ।
          </p>
          <p className="softText">
            This Is Software Repost From Bangladesh Election Commussion,
            Signature & Seal Aren't Required
          </p>
        </section>
      </div>
    </div>
  );
};

export default Verification;
