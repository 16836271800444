import React, { useState } from "react";
import "./voterlist.css";

const VoterList = () => {
  const [tab, setTab] = useState("list");
  return (
    <div style={{ textAlign: "left" }}>
      <div className="vButtons">
        <button
          className={tab === "list" ? tab : ""}
          onClick={() => setTab("list")}
        >
          Voter List
        </button>
        <button
          className={tab === "add" ? tab : ""}
          onClick={() => setTab("add")}
        >
          Add Voter
        </button>
        <form action="">
          <div>
            <label htmlFor="">নাম</label>
            <input type="text" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default VoterList;
