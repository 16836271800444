import { Route, Routes } from "react-router-dom";
import CreateFile from "./Components/CreateFile/CreateFile";
import Dashboard from "./Components/Dashboard/Dashboard";
import FileList from "./Components/FileList/FileList";
import Login from "./Components/Login/Login";
import Profile from "./Components/Profile/Profile";
import Register from "./Components/Register/Register";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MyContextProvider } from "./app.context";
import VerifyUser from "./Components/shared/VerifyUser";
import IdGenerate from "./Components/idGenerate/IdGenerate";
import UserList from "./Components/userList/UserList";
import Testing from "./Testing";
import Verification from "./Components/varification/Verification";
import VoterList from "./Components/voterList/VoterList";




function App() {
  return (
    <div className="App">
      <MyContextProvider>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Routes>
          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/test" element={<Testing />} />
          <Route element={<Dashboard />}>
            <Route path="/profile" element={<VerifyUser><Profile /></VerifyUser>} />
            <Route path="/" element={<VerifyUser><CreateFile /></VerifyUser>} />
            {/* <Route path="/profile" element={<Profile />} /> */}
            {/* <Route path="/" element={<CreateFile />} /> */}
            <Route path="/fileList" element={<FileList />} />
            <Route path="/voterList" element={<VoterList />} />
            {/* <Route path="/generate" element={<IdGenerate />} /> */}
            <Route path="/users" element={<UserList />} />

          </Route>
          <Route path="/generate" element={<IdGenerate />} />
          {/* <Login/> */}
        </Routes>
      </MyContextProvider>
    </div>
  );
}

export default App;
