import axios from "axios";
import { ToastError } from "./SmallComponents";


// local
export const base = "http://localhost:5000";
// export const base = "http://backend.politiq-global.com";


// registration
export const registerUser = async (user) => {
  try {
    const res = await axios.post(`${base}/user/create-account`, user);
    if (res) return res.data.user;
  } catch (error) {
    console.log("err is", error);
    ToastError(error?.message || "Something error")
  }
};

// login
export const loginUser = async (data) => {
  try {
    const res = await axios.post(`${base}/user/login`, data);
    if (res?.data) localStorage.setItem("token", res.data.token);
    if (res?.data) localStorage.setItem("refresh", res.data.refresh);
    return res?.data;
  } catch (error) {
    console.log(error);
    ToastError(error?.message || "Something error")
  }
};



export const getAllId = async (data) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem("token") || ""}`,
    };
    const res = await axios.post(`${base}/id/all-data`, data,{headers});

    return res.data;
  } catch (error) {
    console.log(error);
    ToastError(error?.message || "Something error")
  }
};


export const deletesingleCard = async (id,data) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem("token") || ""}`,
    };
    const res = await axios.post(`${base}/id/delete/${id}`, data,{headers});

    return res.data;
  } catch (error) {
    console.log(error);
    ToastError(error?.message || "Something error")
  }
};




// upload info to database
export const uploadIdInfo = async (data) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      // token: localStorage.getItem("token") || "",
      authorization: `Bearer ${localStorage.getItem("token") || ""}`,
      // Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
    };
    const res = await axios.post(`${base}/all-id`, data,{headers});
    return res.data;
  } catch (error) {
    console.log(error);
    ToastError(error?.message || "Something error")
  }
};

// get all users

export const getAllUser = async (data) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem("token") || ""}`,
    };
    const res = await axios.post(`${base}/user/all-user`, data,{headers});

    return res.data;
  } catch (error) {
    console.log(error);
    ToastError(error?.message || "Something error")
  }
};

// update user
export const updateUser = async (id,data) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem("token") || ""}`,
    };
    const res = await axios.put(`${base}/user/${id}`, data,{headers});

    return res.data;
  } catch (error) {
    console.log(error);
    ToastError(error?.message || "Something error")
  }
};
// delete user
export const deleteUser = async (id,data) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem("token") || ""}`,
    };
    const res = await axios.post(`${base}/user/delete/${id}`, data,{headers});

    return res.data;
  } catch (error) {
    console.log(error);
    ToastError(error?.message || "Something error")
  }
};


// add new user
export const createUser = async (data) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem("token") || ""}`,
    };
    const res = await axios.post(`${base}/user/create-account`, data,{headers});
    return res.data;
  } catch (error) {
    console.log(error);
    ToastError(error?.message || "Something error")
  }
};
