/* eslint-disable no-unused-vars, no-console */
import axios from "axios";
import * as PDFJS from "pdfjs-dist";
import React, { useEffect, useRef, useState } from "react";
import { useMyContext } from "../../app.context";
import { useNavigate } from "react-router-dom";
import { base, uploadIdInfo } from "../shared/api";
import { ToastError, ToastSuccess } from "../shared/SmallComponents";
import { createWorker } from "tesseract.js";
PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`;
const CreateFile = () => {
  const {
    imgDownloaded,
    setImgDownloaded,
    imgDownloadedS,
    setImgDownloadedS,
    user,
    setUser,
    text,
    setText,
    image,
    setImage,
    signature,
    setSignature,
    nameBangla,
    setNameBangla,
    nameEnglish,
    setNameEnglish,
    nid,
    setNid,
    pin,
    setPin,
    nameFather,
    setNameFather,
    nameMother,
    setNameMother,
    birthPlace,
    setBirthPlace,
    dob,
    setDob,
    homeHolding,
    setHomeHolding,
    road,
    setRoad,
    postOffice,
    setPostOffice,
    postalCode,
    setPostalCode,
    upozilla,
    setUpozilla,
    city,
    setCity,
    fulladdress,
    setFulladdress,
    bloodGroup,
    setBloodGroup,
    fullNidData,
    setFullNidData,
    currentAddress,
    setCurrentAddress,
    permanentAddress,
    setPermanentAddress,
    voterNumber,
    setVoterNumber,
    formNumber,
    setFormNumber,
    spouseName,
    setSpouseName,
    pa,
    setPa,
    ca,
    setCa,
    gender,
    setGender,
    edu,
    setEdu,
    serverA, setServerA,
    nidA , setNidA,
    serverPresent,
    setServerPresent,
    mob,
    occupation,
    religion,
    religionOther,
    setMob,
    setOccupation,
    setReligion,
    setReligionOther
  } = useMyContext();

  const ref = useRef(null);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [today, setToday] = useState(
    new Date()
      .toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
  );

  const getData = async () => {
    ref.current.click();
  };
  const OnchangesFile = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };



  useEffect(() => {



    if (file) {
      //  setTimeout(() => {
      //   await axios.get('http://localhost:5000/resume')
      //   }, 1000);
      const Genaret = async () => {
        //  let convertApi = ConvertApi.auth('x3VkcCsPjXaureeV')
        const pdf = await PDFJS.getDocument(URL.createObjectURL(file)).promise;
        const page = await pdf.getPage(1);
        const text = await page.getTextContent();
        // console.log("text is ", text );
        const textItems = text.items
          .map((s) => s.str)
          .join("")
          // .trim(" ")
          .replace(/\s+/g, " ");
        // .replace(/[\u200B-\u200D\uFEFF]+/g, " ");
        // .replace(/\s+/g, " ")
        // .replace(/\s*([\u200B-\u200D\uFEFF])\s*/g, "$1");

        // new with tesseract =====================================
        // const ocrText = await extractAndOCR(page);

        // console.log("orc text", ocrText );






        // new with tesseract end ========================

        const page2 = await pdf.getPage(2);
        const text2 = await page2.getTextContent();
        // console.log("text is 2 ", text2 );
        const textItems2 = text2.items
          .map((s) => s.str)
          .join("")
          // .trim(" ")
          .replace(/\s+/g, " ");
        // .replace(/[\u200B-\u200D\uFEFF]+/g, " ");
        // .replace(/\s+/g, " ")
        // .replace(/\s*([\u200B-\u200D\uFEFF])\s*/g, "$1");

        setText(textItems + textItems2);
        const formData = new FormData();
        formData.append("thumb", file);

        await axios
          .post(`${base}/resume`, formData, {
            headers: {
              "Content-Type": "multipart/form-data", // Important for file uploads
              // Add any other headers you need
            },
          })
          .then((response) => {
            // window.location.reload();
            // Handle the response here
            setImage(
              `${base}/${
                response?.data[0]
              }.png?timestamp=${new Date().getTime()}`
            );

            fetch(
              `${base}/${
                response?.data[0]
              }.png?timestamp=${new Date().getTime()}`
            )
              .then((response) => response.blob())
              .then((imageBlob) => {
                setImgDownloaded(imageBlob);

                // upload to cloudinary

                // const data = new FormData();
                // data.append("file", imageBlob);
                // data.append("upload_preset", "ml_defaultru");

              })
              .catch((error) => {
                console.error("Error fetching the image:", error);
              });

            setSignature(
              `${base}/${
                response?.data[1]
              }.png?timestamp=${new Date().getTime()}`
            );

            fetch(
              `${base}/${
                response?.data[1]
              }.png?timestamp=${new Date().getTime()}`
            )
              .then((response) => response.blob())
              .then((imageBlob) => {
                setImgDownloadedS(imageBlob);

              })
              .catch((error) => {
                console.error("Error fetching the image:", error);
              });
          })
          .catch((error) => {
            console.log("error", error);
            // Handle errors
          });
      };
      Genaret();
    }
  }, [
    file,
    nameBangla,
    nameEnglish,
    nid,
    pin,
    nameFather,
    nameMother,
    birthPlace,
    dob,
    homeHolding,
    road,
    postOffice,
    postalCode,
    upozilla,
    city,
    fulladdress,
    bloodGroup,
    setImage,
    setSignature,
    setText,

  ]);

  useEffect(() => {
    if (text) {
      console.log("text", text);

      // others

      const othersData = (starting, aName, ending, saving) => {
        const firstIndex = text.indexOf(starting);
        const start = firstIndex + starting.length;
        const end = text.indexOf(ending, firstIndex);

        if (start !== -1 && end !== -1 && start < end) {
          const middlePart = text.slice(start, end).trim();
          // saving((prevCA) => ({ ...prevCA, [aName]: middlePart }));
          saving(middlePart);
        } else {
          console.error(` not found for pa  ${aName}`);
        }
      };
      const other2=(starting, aName, ending, saving)=>{

          // const start = text.indexOf(starting) + starting.length;
          // const end = text.lastIndexOf(ending);

          const firstIndex = text.indexOf(starting);
          const secondIndex = text.indexOf(starting, firstIndex + 1);
          const thirdIndex = text.indexOf(starting, secondIndex + 1);
          const fourIndex = text.indexOf(starting, thirdIndex + 1);

          const start = secondIndex + starting.length;
          const end = text.indexOf(ending, secondIndex);
          // const end = text.indexOf(ending, text.lastIndexOf(starting));

          // if (starting == "Mouza/Moholla") {
          // console.log(`starting  ${starting}`, start);
          // console.log(`ending  ${ending}`, end);

          // }

          if (start !== -1 && end !== -1 && start < end) {
            const middlePart = text.slice(start, end).trim();
            saving(middlePart);
          } else {
            console.error(` not found for pa  ${aName}`);
          }

      }

      othersData("Gender", "Division", "Marital", setGender);
      othersData("Education", "Education", "Education Other", setEdu);
      othersData("Spouse Name", "SpouseName", "Gender", setSpouseName);
      othersData("Birth Place", "birthPlace", "Birth Other", setBirthPlace);
      othersData("Occupation", "Occupation", "Disability", setOccupation);

      othersData("Religion", "Religion", "Religion Other", setReligion);
      othersData("Religion Other", "ReligionOther", "Death Date Of", setReligionOther);
      other2("Mobile", "Mobile", "Religion", setMob);


      // others

      // get present address
      const presentData2 = (starting, aName, ending) => {
        const start = text.indexOf(starting) + starting.length;
        const end = text.indexOf(ending);
        if (start !== -1 && end !== -1 && start < end) {
          const middlePart = text.slice(start, end).trim();
          // console.log(starting, "iss", middlePart);
          //  setCa({...ca, [aName]: middlePart});
          setCa((prevCA) => ({ ...prevCA, [aName]: middlePart }));
        } else {
          console.error(` not found for ca ${aName}`);
        }
      };
      const presentData = (starting, aName, ending) => {
        const firstIndex = text.indexOf(starting);
        const start = firstIndex + starting.length;
        const end = text.indexOf(ending, firstIndex);

        // console.log(`starting  ${starting}`, start);
        // console.log(`ending  ${ending}`, end);

        if (start !== -1 && end !== -1 && start < end) {
          const middlePart = text.slice(start, end).trim();
          setCa((prevCA) => ({ ...prevCA, [aName]: middlePart }));
        } else {
          console.error(` not found for pa  ${aName}`);
        }
      };

      presentData("Division", "Division", "District");
      presentData("District", "District", "RMO");
      presentData("RMO", "RMO", "CityCorporationOrMunicipality");
      presentData(
        "CityCorporationOrMunicipality",
        "CityCorporationOrMunicipality",
        "Upozila"
      );
      presentData("Upozila", "Upozila", "Union/Ward");
      presentData("Union/Ward", "UnionWard", "Mouza/Moholla");
      presentData("Mouza/Moholla", "MouzaMoholla", "AdditionalMouza/Moholla");
      presentData(
        "AdditionalMouza/Moholla",
        "AdditionalMouzaMoholla",
        "Ward ForUnionPorishod"
      );
      presentData(
        "Ward ForUnionPorishod",
        "WardForUnionPorishod",
        "Village/Road"
      );
      presentData("Village/Road", "VillageRoad", "AdditionalVillage/Road");
      presentData(
        "AdditionalVillage/Road",
        "AdditionalVillageRoad",
        "Home/HoldingNo"
      );
      presentData("Home/HoldingNo", "HomeHoldingNo", "Post Office");
      presentData("Post Office", "PostOffice", "Postal Code");
      presentData("Postal Code", "PostalCode", "Region");
      presentData("Region", "Region", "Permanent Address");

      // get present address

      // get permanent data

      const permanentData3 = (starting, aName, ending) => {
        // const start = text.indexOf(starting) + starting.length;
        // const end = text.lastIndexOf(ending);

        const firstIndex = text.indexOf(starting);
        const secondIndex = text.indexOf(starting, firstIndex + 1);
        const thirdIndex = text.indexOf(starting, secondIndex + 1);
        const fourIndex = text.indexOf(starting, thirdIndex + 1);

        const start = secondIndex + starting.length;
        const end = text.indexOf(ending, secondIndex);
        // const end = text.indexOf(ending, text.lastIndexOf(starting));

        // if (starting == "Mouza/Moholla") {
        // console.log(`starting  ${starting}`, start);
        // console.log(`ending  ${ending}`, end);

        // }

        if (start !== -1 && end !== -1 && start < end) {
          const middlePart = text.slice(start, end).trim();
          //  console.log(starting, " per iss", middlePart);
          //  setPa({...pa, [aName]: middlePart});
          setPa((prevPA) => ({ ...prevPA, [aName]: middlePart }));
        } else {
          console.error(` not found for pa  ${aName}`);
        }
      };
      const permanentData4 = (starting, aName, ending) => {
        // const start = text.indexOf(starting) + starting.length;
        // const end = text.lastIndexOf(ending);

        const firstIndex = text.indexOf(starting);
        const secondIndex = text.indexOf(starting, firstIndex + 1);
        const thirdIndex = text.indexOf(starting, secondIndex + 1);
        const fourIndex = text.indexOf(starting, thirdIndex + 1);

        const start = thirdIndex + starting.length;
        const end = text.indexOf(ending, thirdIndex);
        // const end = text.indexOf(ending, text.lastIndexOf(starting));

        // if (starting == "Mouza/Moholla") {
        // console.log(`starting  ${starting}`, start);
        // console.log(`ending  ${ending}`, end);

        // }

        if (start !== -1 && end !== -1 && start < end) {
          const middlePart = text.slice(start, end).trim();
          //  console.log(starting, " per iss", middlePart);
          //  setPa({...pa, [aName]: middlePart});
          setPa((prevPA) => ({ ...prevPA, [aName]: middlePart }));
        } else {
          console.error(` not found for pa  ${aName}`);
        }
      };
      const permanentData5 = (starting, aName, ending, ending2) => {
        const firstIndex = text.indexOf(starting);
        const secondIndex = text.indexOf(starting, firstIndex + 1);

        const start = secondIndex + starting.length;
        const end = text.indexOf(ending, secondIndex);
        const end2 = text.indexOf(ending2, secondIndex);
        // console.log(`text   ${starting}`, start);
        // console.log(`starting  ${starting}`, start);
        // console.log(`ending  ${ending}`, end);
        // console.log(`ending2   ${ending2}`, end2);

        if (start !== -1 && end !== -1 && start < end) {
          const middlePart = text.slice(start, end).trim();
          setPa((prevPA) => ({ ...prevPA, [aName]: middlePart }));
        } else if (start !== -1 && end2 !== -1 && start < end2) {
          const middlePart = text.slice(start, end2).trim();
          setPa((prevPA) => ({ ...prevPA, [aName]: middlePart }));
        } else {
          console.error(` not found for pa  ${aName}`);
        }
      };



      permanentData3("Division", "Division", "District");
      permanentData3("District", "District", "RMO");
      permanentData3("RMO", "RMO", "CityCorporationOrMunicipality");
      permanentData3(
        "CityCorporationOrMunicipality",
        "CityCorporationOrMunicipality",
        "Upozila"
      );
      permanentData3("Upozila", "Upozila", "Union/Ward");
      permanentData3("Union/Ward", "UnionWard", "Mouza/Moholla");
      permanentData4(
        "Mouza/Moholla",
        "MouzaMoholla",
        "AdditionalMouza/Moholla"
      );
      permanentData3(
        "AdditionalMouza/Moholla",
        "AdditionalMouzaMoholla",
        "Ward ForUnionPorishod"
      );
      permanentData3(
        "Ward ForUnionPorishod",
        "WardForUnionPorishod",
        "Village/Road"
      );
      permanentData4("Village/Road", "VillageRoad", "AdditionalVillage/Road");
      permanentData3(
        "AdditionalVillage/Road",
        "AdditionalVillageRoad",
        "Home/HoldingNo"
      );
      permanentData3("Home/HoldingNo", "HomeHoldingNo", "Post Office");
      permanentData3("Post Office", "PostOffice", "Postal Code");
      permanentData3("Postal Code", "PostalCode", "Region");
      permanentData5("Region", "Region", "Foreign Address", "Education");

      // get permanent data

      const start = text.indexOf("Name(Bangla)") + "Name(Bangla)".length;
      const end = text.indexOf("Name(English)");
      setFullNidData(text);
      if (start !== -1 && end !== -1 && start < end) {
        const middlePart = text.slice(start, end).trim();
        // console.log("bangla name", middlePart);
        setNameBangla(middlePart);
      }
      const startengnameIndex =
        text.indexOf("Name(English)") + "Name(English)".length;
      const endengnameIndex = text.indexOf("Date of Birth");
      if (
        startengnameIndex !== -1 &&
        endengnameIndex !== -1 &&
        startengnameIndex < endengnameIndex
      ) {
        const name = text.slice(startengnameIndex, endengnameIndex).trim();
        // console.log("english name", name);
        setNameEnglish(name);
      }
      // get father name
      const startfathIndex = text.indexOf("Father Name") + "Father Name".length;
      const endfathIndex = text.indexOf("Mother Name");
      if (
        startfathIndex !== -1 &&
        endfathIndex !== -1 &&
        startfathIndex < endfathIndex
      ) {
        const name = text.slice(startfathIndex, endfathIndex).trim();
        // console.log("father name", name);
        setNameFather(name);
      }
      // get father name
      // get mother name
      const startmotherIndex =
        text.indexOf("Mother Name") + "Mother Name".length;
      const endmotherIndex = text.indexOf("Spouse Name");
      if (
        startmotherIndex !== -1 &&
        endmotherIndex !== -1 &&
        startmotherIndex < endmotherIndex
      ) {
        const name = text.slice(startmotherIndex, endmotherIndex).trim();
        setNameMother(name);
        // console.log("mother name", name);
      }
      // get mother name
      // Split the input string by spaces
      const nidMatch = text.match(/National\s+ID\s+(\d+)/);
      const pinMatch = text.match(/Pin\s+(\d+)/);
      if (nidMatch && nidMatch[1] && pinMatch && pinMatch[1]) {
        setNid(nidMatch[1]);
        setPin(pinMatch[1]);
        // console.log("nid no", nidMatch[1]);
        // console.log("pin ", pinMatch[1]);
      }
      const startText = "Birth Place";
      const endText = "Birth Other";
      // Find the index of the start text and end text
      const startIndex = text.indexOf(startText);
      const endIndex = text.indexOf(endText);
      if (startIndex !== -1 && endIndex !== -1) {
        const middleValue = text
          .substring(startIndex + startText.length, endIndex)
          .trim();
        // setBirthPlace(middleValue);
        // console.log("birth place", middleValue);
      }
      const dobMatch = text.match(/Date of Birth\s+(\d{4}-\d{2}-\d{2})/);
      if (dobMatch && dobMatch[1]) {
        // Format the date to "dd MMM yyyy" format
        const parts = dobMatch[1].split("-");
        if (parts.length === 3) {
          const [year, month, day] = parts;
          const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          setDob(`${day} ${months[parseInt(month, 10) - 1]} ${year}`);
          // console.log(
          //   "dob",
          //   `${day} ${months[parseInt(month, 10) - 1]} ${year}`
          // );
        }
      }
      // get home holding number
      const startHomeIndex = text.indexOf("Holding No") + "Holding No".length;
      const endHomeIndex = text.indexOf("Post Office");
      if (
        startHomeIndex !== -1 &&
        endHomeIndex !== -1 &&
        startHomeIndex < endHomeIndex
      ) {
        const name = text.slice(startHomeIndex, endHomeIndex).trim();
        // console.log("home holding", name);
        setHomeHolding(name);
      }
      // get home holding number
      // get road number
      const startRoadIndex =
        text.indexOf("Additional Village/Road") +
        "Additional Village/Road".length;
      const endRoadIndex = text.indexOf("Home/Holding");
      if (
        startRoadIndex !== -1 &&
        endRoadIndex !== -1 &&
        startRoadIndex < endRoadIndex
      ) {
        const name = text.slice(startRoadIndex, endRoadIndex).trim();
        // console.log("road", name);
        setRoad(name);
      }
      // get road number
      // get post office
      const startPostIndex = text.indexOf("Post Office") + "Post Office".length;
      const endPostIndex = text.indexOf("Postal Code");
      if (
        startPostIndex !== -1 &&
        endPostIndex !== -1 &&
        startPostIndex < endPostIndex
      ) {
        const name = text.slice(startPostIndex, endPostIndex).trim();
        // console.log("postofiice", name);
        setPostOffice(name);
      }
      // get postal code
      const startPostalIndex =
        text.indexOf("Postal Code") + "Postal Code".length;
      const endPostalIndex = text.indexOf("Region");
      if (
        startPostalIndex !== -1 &&
        endPostalIndex !== -1 &&
        startPostalIndex < endPostalIndex
      ) {
        const name = text.slice(startPostalIndex, endPostalIndex).trim();
        setPostalCode(name);
        // console.log("post code", name);
      }
      // upozila
      const startUpozilaIndex = text.indexOf("Upozila") + "Upozila".length;
      const endUpozilaIndex = text.indexOf("Union/Ward");
      if (
        startUpozilaIndex !== -1 &&
        endUpozilaIndex !== -1 &&
        startUpozilaIndex < endUpozilaIndex
      ) {
        const name = text.slice(startUpozilaIndex, endUpozilaIndex).trim();
        setUpozilla(name);
        // console.log("uposzila", name);
      }
      // upozila
      // city
      const startCityIndex =
        text.indexOf("Municipality") + "Municipality".length;
      const endCityIndex = text.indexOf("Upozila");
      if (
        startCityIndex !== -1 &&
        endCityIndex !== -1 &&
        startCityIndex < endCityIndex
      ) {
        const name = text.slice(startCityIndex, endCityIndex).trim();
        setCity(name);
        // console.log("city", name);
      }
      // city

      // get blood group
      const startBloodIndex =
        text.indexOf("Blood Group") + "Blood Group".length;
      const endBloodIndex = text.indexOf("TIN");
      if (
        startBloodIndex !== -1 &&
        endBloodIndex !== -1 &&
        startBloodIndex < endBloodIndex
      ) {
        const name = text.slice(startBloodIndex, endBloodIndex).trim();
        setBloodGroup(name);
        // console.log("blood", name);
      }
      // get blood group
      // get Voter Number
      const voterNoMatch = text?.match(/Voter No (\d+)/);
      if (voterNoMatch) {
        setVoterNumber(voterNoMatch[1]);
        // console.log("voter bumber", voterNoMatch[1]);
      }
      const formNoMatch = text?.match(/Form No (\w+)/);
      if (formNoMatch) {
        const numericPart = formNoMatch[1].match(/\d+/);
        if (numericPart) {
          setFormNumber(numericPart[0]);
          // console.log("form number", numericPart[0]);
        }
      }
      // spouseName, setSpouseName
      const spouseNamePattern = /Spouse Name (.+?)(?: Gender|$)/;
      const spouseNameMatch = text?.match(spouseNamePattern);
      if (spouseNameMatch) {
        // setSpouseName(spouseNameMatch[1]);
        // console.log("spuse", spouseNameMatch[1]);
      }
    }
  }, [
    text,
    setBirthPlace,
    setBloodGroup,
    setCa,
    setCity,
    setDob,
    setFormNumber,
    setFullNidData,
    setHomeHolding,
    setNameBangla,
    setNameEnglish,
    setNameFather,
    setNameMother,
    setNid,
    setPa,
    setPin,
    setPostOffice,
    setPostalCode,
    setRoad,
    setSpouseName,
    setUpozilla,
    setVoterNumber,
  ]);

  useEffect(() => {


  // if(image && imgDownloaded){
  //   const performOCR = async () => {
  //     const worker = await createWorker('eng');
  //     const ret = await worker.recognize("dkjfdskjfkdsjf");
  //     console.log("newdd", ret?.data?.text);
  //     await worker.terminate();
  //     return text;
  //   };
  //   performOCR()
  //   .then(s=>{
  //    console.log("sss is ", s);
  //   })
  //   .catch(error=>{
  //    console.log("erc error is t", error);
  //   })
  // }

    if (homeHolding || road || postOffice || postalCode || upozilla || city) {
      setFulladdress(
        `বাসা/হোল্ডিং: ${homeHolding}, গ্রাম/রাস্তা:${road}, ডাকঘর:${postOffice}-${postalCode}, ${upozilla}, ${city}`
      );
    }
    setServerA(
      `বাসা/হোল্ডিংঃ ${pa?.HomeHoldingNo}, গ্রাম/রাস্তাঃ ${pa?.VillageRoad || pa?.AdditionalVillageRoad}, মৌজা/মহল্লাঃ ${pa?.MouzaMoholla || pa?.AdditionalMouzaMoholla}, ইউনিয়ন/ওয়ার্ডঃ ${pa?.UnionWard}, পোষ্ট অফিসঃ ${pa?.PostOffice}, পোষ্ট কোডঃ ${pa?.PostalCode}, উপজেলাঃ ${pa?.Upozila}, জেলাঃ ${pa?.District}, অঞ্চলঃ ${pa?.Division}, বিভাগঃ ${pa?.Division} `
    )
    setServerPresent(
      `বাসা/হোল্ডিংঃ ${ca?.HomeHoldingNo}, গ্রাম/রাস্তাঃ ${ca?.VillageRoad || ca?.AdditionalVillageRoad}, মৌজা/মহল্লাঃ ${ca?.MouzaMoholla || ca?.AdditionalMouzaMoholla}, ইউনিয়ন/ওয়ার্ডঃ ${ca?.UnionWard}, পোষ্ট অফিসঃ ${ca?.PostOffice}, পোষ্ট কোডঃ ${ca?.PostalCode}, উপজেলাঃ ${ca?.Upozila}, জেলাঃ ${ca?.District}, অঞ্চলঃ ${ca?.Division}, বিভাগঃ ${ca?.Division} `
    )

    setNidA(
      `বাসা/হোল্ডিং: ${pa?.HomeHoldingNo}, গ্রাম/রাস্তা: ${pa?.VillageRoad || pa?.AdditionalVillageRoad}, ডাকঘর: ${pa?.PostOffice} - ${pa?.PostalCode}, ${pa?.Upozila}, ${pa?.District}`
    )
  }, [
    pa?.VillageRoad,
    pa?.AdditionalVillageRoad,
    pa?.District,
    pa?.Upozila,
    pa?.PostOffice,
    pa?.PostalCode,
    pa?.HomeHoldingNo,
    pa?.Division,
    ca?.VillageRoad,
    ca?.AdditionalVillageRoad,
    ca?.District,
    ca?.Upozila,
    ca?.PostOffice,
    ca?.PostalCode,
    ca?.HomeHoldingNo,
    ca?.Division,
    homeHolding,
    road,
    postOffice,
    postalCode,
    upozilla,
    city,
    setFulladdress,
    image,
    imgDownloaded
  ]);

  // const handleData = async (e) => {
  //   e.preventDefault();

  //   const idInfo = {
  //     userEmail: user?.email,
  //     id: user?._id,
  //     text,
  //     nameBangla,
  //     nameEnglish,
  //     nid,
  //     pin,
  //     nameFather,
  //     nameMother,
  //     birthPlace,
  //     dob,
  //     homeHolding,
  //     road,
  //     postOffice,
  //     postalCode,
  //     upozilla,
  //     city,
  //     fulladdress,
  //     bloodGroup,
  //     image:imgDownloaded,
  //     signature:imgDownloadedS,
  //   };

  //   const userRes =  uploadIdInfo(idInfo);
  //   if (userRes) {
  //     ToastSuccess("Saved in Database");
  //      navigate("/generate");
  //   }

  // };

  const handleData = async (e) => {
    e.preventDefault();


    // new
    const data = new FormData();
    data.append("file", imgDownloaded);
    data.append("upload_preset", "ml_defaultru");
    data.append("cloud_name", "dc7xchqbj");

    fetch("http://api.cloudinary.com/v1_1/dc7xchqbj/image/upload", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data2) => {
        // setUploadedUrl(data2.url);
        // ToastSuccess("Successfully image uploaded to server");

        // new 2
        const data3 = new FormData();
        data3.append("file", imgDownloadedS);
        data3.append("upload_preset", "ml_defaultru");
        data3.append("cloud_name", "dc7xchqbj");

        fetch("http://api.cloudinary.com/v1_1/dc7xchqbj/image/upload", {
          method: "POST",
          body: data3,
        })
          .then((res) => res.json())
          .then((data4) => {
            // setUploadedUrl(data4.url);
            // ToastSuccess("Successfully image uploaded to server");
            // finally upload
            const idInfo = {
              userEmail: user?.email,
              id: user?._id,
              // text,
              nameBangla,
              nameEnglish,
              nid,
              pin,
              nameFather,
              nameMother,
              birthPlace,
              spouseName,
              dob,
              gender,
              edu,
              pa,
              ca,
              bloodGroup,
              mob,
              religion,
              religionOther,
              occupation,
              image: data2.url,
              signature: data4.url,
            };

            const userRes = uploadIdInfo(idInfo);
            if (userRes) {
              ToastSuccess("Saved in Database");
              navigate("/generate");
            }

            // navigate("/generate");

            // finally upload
          })
          .catch((err) => {
            ToastError(err?.message || "Image not uploaded to server");
          });
        // new 2
      })
      .catch((err) => {
        ToastError(err?.message || "Image not uploaded to server");
      });
    // new
  };
  const downlowdId = async (e) => {
    // e.preventDefault();
    navigate("/generate");
  };

  const serverCopy = () => {
    navigate("/verification");
  };

  // console.log("data",
  // imgDownloaded,
  // imgDownloadedS,
  // user,
  // text,
  // image,
  // signature,
  // nameBangla,
  // nameEnglish,
  // nid,
  // pin,
  // nameFather,
  // nameMother,
  // birthPlace,
  // dob,
  // homeHolding,
  // road,
  // postOffice,
  // postalCode,
  // upozilla,
  // city,
  // fulladdress,
  // bloodGroup,
  // fullNidData,
  // currentAddress,
  // permanentAddress,
  // voterNumber,
  // formNumber,
  // spouseName,
  // pa,
  // ca,);

  // console.log("imag", imgDownloaded, imgDownloadedS);
  return (
    <div>
      <marquee
        style={{
          padding: 10,
          background: "white",
          borderRadius: 5,
          border: "1px solid #0d6efd",
          margin: 10,
          width: "calc(100% - 20px)",
          marginTop: "-10px",
          marginBottom: 20,
        }}
      >
        অটো রিচার্জ চালু☑️ মেক রেট ৫ টাকা🥳 সাইটে নিউ আপডেট এর জন্য কোনো সমস্যা
        হলে সাপোর্টে ক্লিক করে আমাদেরকে জানাতে পারেন!
      </marquee>
      <section>
        <div
          className="file-drop-zone clickable"
          tabIndex={-1}
          onClick={() => getData()}
        >
          <div className="file-drop-zone-title">
            <div className="upload-area">
              <p>ব্রাউজ বা টেনে আনুন সাইন কপি পিডিএফ ফাইল</p>
              <div>
                <button type="button">ব্রাউজ ফাইল</button>
                <input
                  type="file"
                  ref={ref}
                  onChange={(e) => OnchangesFile(e)}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleData} style={{ textAlign: "left" }}>
          <div>
            <div className="row">
              <div
                className="col-md-6 col-sm-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="form-group">
                  <label htmlFor="first" style={{ display: "block" }}>
                    NID Photo
                  </label>
                  <input
                    style={{ maxWidth: "60%" }}
                    type="file"
                    defaultValue=""
                    id="imageUrl1"
                    name="imageUrl1"
                  />

                  <input
                    type="text"
                    style={{ display: "none" }}
                    defaultValue=""
                    id=" imageUrl1"
                    name="imageUrl12"
                  />
                </div>
                <img
                  style={{ marginLeft: "-20%", width: 55 }}
                  id="imageUrl1_url"
                  src={image}
                />
              </div>
              {/*  col-md-6   */}
              <div
                className="col-md-6 col-sm-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="form-group">
                  <label htmlFor="last" style={{ display: "block" }}>
                    Signature
                  </label>
                  <input
                    style={{ maxWidth: "60%" }}
                    type="file"
                    defaultValue=""
                    id="imageUrl2"
                    name="imageUrl2"
                  />
                  <input
                    type="text"
                    style={{ display: "none" }}
                    defaultValue=""
                    id="imageUrl2"
                    name="imageUrl22"
                  />
                </div>
                <img
                  style={{ marginLeft: "-20%", width: 55 }}
                  id="imageUrl2_url"
                  src={signature}
                />
              </div>
              {/*  col-md-6   */}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="first">Name (Bangla)</label>
                  <input
                    type="text"
                    name="nameBangla"
                    className="form-control"
                    defaultValue={nameBangla}
                    onChange={(e) => {
                      setNameBangla(e.target.value);
                    }}
                    // value={nameBangla}
                    id="nameBangla"
                  />
                </div>
              </div>
              {/*  col-md-6   */}
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="last">Name (English)</label>
                  <input
                    type="text"
                    name="nameEnglish"
                    className="form-control"
                    defaultValue={nameEnglish}
                    onChange={(e) => {
                      setNameEnglish(e.target.value);
                    }}
                    // value={nameEnglish}
                    id="nameEnglish"
                  />
                </div>
              </div>
              {/*  col-md-6   */}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="company">NID Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="nid"
                    name="nid"
                    defaultValue={nid}
                    onChange={(e) => {
                      setNid(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/*  col-md-6   */}
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="phone">PIN Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="pin"
                    id="pin"
                    defaultValue={pin}
                    onChange={(e) => {
                      setPin(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/*  col-md-6   */}
            </div>
            {/*  row   */}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email">Father Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="nameFather"
                    name="nameFather"
                    defaultValue={nameFather}
                    onChange={(e) => {
                      setNameFather(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/*  col-md-6   */}
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="url">Mother Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="nameMother"
                    id="nameMother"
                    defaultValue={nameMother}
                    onChange={(e) => {
                      setNameMother(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/*  col-md-6   */}
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="email">Birth Place</label>
                  <input
                    type="text"
                    className="form-control"
                    id="birthPlace"
                    name="birthPlace"
                    defaultValue={birthPlace}
                    onChange={(e) => {
                      setBirthPlace(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/*  col-md-6   */}
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="url">Date Of Birth</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dob"
                    name="dob"
                    defaultValue={dob}
                    onChange={(e) => {
                      setDob(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="url">Issue date</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dob"
                    name="issued"
                    // defaultValue={today}
                    value={today}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="url">Blood Group</label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={bloodGroup}
                    onChange={(e) => {
                      setBloodGroup(e.target.value);
                    }}
                  />

                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="url">Spouse Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={spouseName}
                    onChange={(e) => {
                      setSpouseName(e.target.value);
                    }}
                  />

                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="url">Mobile</label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={mob}
                    onChange={(e) => {
                      setMob(e.target.value);
                    }}
                  />

                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="url">Religion</label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={religion}
                    onChange={(e) => {
                      setReligion(e.target.value);
                    }}
                  />

                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="url">Religion Other</label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={religionOther}
                    onChange={(e) => {
                      setReligionOther(e.target.value); 
                    }}
                  />

                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="url">Occupation</label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={occupation}
                    onChange={(e) => {
                      setOccupation(e.target.value);
                    }}
                  />

                </div>
              </div>












              {/*  col-md-6   */}
            </div>{" "}

            <div className="  ">
              <label htmlFor="">বর্তমান ঠিকানা : </label>
              <div className="row  ">
                <div className=" col-6 col-md-3 mt-1  ">
                  <label htmlFor="" className="text-xs ">
                     গ্রাম :{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={ca?.VillageRoad || ca?.AdditionalVillageRoad}
                    onChange={(e) => {
                      setCa(prev=>(({...prev, VillageRoad:e.target.value})));
                    }}
                  />
                </div>
                <div className=" col-6 col-md-3  mt-1  ">
                  <label htmlFor="" className="text-xs ">
                   ডাকঘর :{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={ca?.PostOffice}
                    onChange={(e) => {
                      setCa(prev=>(({...prev, PostOffice:e.target.value})));
                    }}
                  />
                </div>

                <div className=" col-6 col-md-3  mt-1  ">
                  <label htmlFor="" className="text-xs ">
                   পোস্ট কোড:{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={ca?.PostalCode }
                    onChange={(e) => {
                      setCa(prev=>(({...prev, PostalCode:e.target.value})));
                    }}
                  />
                </div>
                <div className=" col-6 col-md-3  mt-1  ">
                  <label htmlFor="" className="text-xs ">
                   উপজেলা :{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={ca?.Upozila }
                    onChange={(e) => {
                      setCa(prev=>(({...prev, Upozila:e.target.value})));
                    }}
                  />
                </div>
                <div className=" col-6 col-md-3  mt-1  ">
                  <label htmlFor="" className="text-xs ">
                   জেলা :{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={ca?.District }
                    onChange={(e) => {
                      setCa(prev=>(({...prev, District:e.target.value})));
                    }}
                  />
                </div>
                <div className=" col-6 col-md-3  mt-1  ">
                  <label htmlFor="" className="text-xs ">
                   বিভাগ :{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={ca?.Division }
                    onChange={(e) => {
                      setCa(prev=>(({...prev, Division:e.target.value})));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className=" mt-4 ">
              <label htmlFor="">স্থায়ী ঠিকানা : </label>
              <div className="row  ">
              <div className=" col-6 col-md-3 mt-1  ">
                  <label htmlFor="" className="text-xs ">
                   গ্রাম :{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={pa?.VillageRoad || pa?.AdditionalVillageRoad}
                    onChange={(e) => {
                      setPa(prev=>(({...prev, VillageRoad:e.target.value})));
                    }}
                  />
                </div>
                <div className=" col-6 col-md-3  mt-1  ">
                  <label htmlFor="" className="text-xs ">
                   ডাকঘর :{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={pa?.PostOffice}
                    onChange={(e) => {
                      setPa(prev=>(({...prev, PostOffice:e.target.value})));
                    }}
                  />
                </div>

                <div className=" col-6 col-md-3  mt-1  ">
                  <label htmlFor="" className="text-xs ">
                   পোস্ট কোড:{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={pa?.PostalCode }
                    onChange={(e) => {
                      setPa(prev=>(({...prev, PostalCode:e.target.value})));
                    }}
                  />
                </div>
                <div className=" col-6 col-md-3  mt-1  ">
                  <label htmlFor="" className="text-xs ">
                   উপজেলা :{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={pa?.Upozila }
                    onChange={(e) => {
                      setPa(prev=>(({...prev, Upozila:e.target.value})));
                    }}
                  />
                </div>
                <div className=" col-6 col-md-3  mt-1  ">
                  <label htmlFor="" className="text-xs ">
                   জেলা :{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={pa?.District }
                    onChange={(e) => {
                      setPa(prev=>(({...prev, District:e.target.value})));
                    }}
                  />
                </div>
                <div className=" col-6 col-md-3  mt-1  ">
                  <label htmlFor="" className="text-xs ">
                   বিভাগ :{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bloodGroup"
                    name="bloodGroup"
                    defaultValue={pa?.Division }
                    onChange={(e) => {
                      setPa(prev=>(({...prev, Division:e.target.value})));
                    }}
                  />
                </div>


              </div>
            </div>


            <div className="row mt-4 ">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="email">NID Full Address: </label>
                  <textarea
                    className="form-control"
                    id="fulladdress"
                    name="fulladdress"
                    // value=""
                    rows={2}
                    value={nidA}
                    onChange={(e) => {
                      setNidA(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-2 ">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="email">Server Copy Present Address: </label>
                  <textarea
                    className="form-control"
                    id="fulladdress"
                    name="fulladdress"
                    // value=""
                    rows={2}
                    value={serverPresent}
                    onChange={(e) => {
                      setServerPresent(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-2 ">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="email">Server Copy Permanent Address: </label>
                  <textarea
                    className="form-control"
                    id="fulladdress"
                    name="fulladdress"
                    // value=""
                    rows={2}
                    value={serverA}
                    onChange={(e) => {
                      setServerA(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {/*  row   */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 50,
                marginTop:"20px" ,
              }}
            >
              <span>Note: You will be charged 5 tk for this nid creation</span>
              <br />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <button
                name="submit"
                type="submit"
                className="btn btn-primary"
                style={{ color: "black" }}
              >
                Download NID & Save
              </button>
            </div>
          </div>
        </form>
        <button
          name=""
          onClick={downlowdId}
          type="submit"
          className="btn btn-primary"
          style={{ color: "black", marginBottom: 15 }}
        >
          Download NID
        </button> <br />
        <button
          name=""
          onClick={serverCopy}
          type="submit"
          className="btn btn-primary"
          style={{ color: "black", marginBottom: 10 }}
        >
          Download Server Copy
        </button>
      </section>
    </div>
  );
};

export default CreateFile;
