/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "tailwindcss/tailwind.css";
import "./idgenerate.css";
import sign from "../../assets/sign.jpg";
import { useMyContext } from "../../app.context";

const IdGenerate = () => {
  const {
    user,
    text,
    image,
    signature,
    nameBangla,
    nameEnglish,
    nid,
    pin,
    nameFather,
    nameMother,
    birthPlace,
    dob,
    homeHolding,
    road,
    postOffice,
    postalCode,
    upozilla,
    city,
    fulladdress,
    bloodGroup,
    imgDownloaded,
    imgDownloadedS,
    pa,
    ca,
    gender,
    nidA,
    serverA,
  } = useMyContext();

  const [nameModify, setNameModify] = useState();
  const [dobModify, setDobModify] = useState();
  // console.log("cu", currentAddress);
  // console.log("per", permanentAddress);

  useEffect(() => {
    const getReadyName = (cName) => {
      if (cName !== (null || undefined)) {
        if (cName.indexOf(" ") !== -1) {
          var modifiedName = cName.split(" ").join("+");
          console.log(modifiedName);
          return modifiedName;
        } else {
          console.log("No space found in the string.", cName);
          return cName;
        }
      } else {
        console.log("Null ", cName);
        return cName;
      }
    };

    setNameModify(getReadyName(nameEnglish));
    setDobModify(getReadyName(dob));
  }, [nameEnglish, dob]);

  const [cimage, setcImage] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setcImage(sign);
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setcImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div className="idPage">
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      <main className="mt-1" id="">
        <div>
          <main className="w-full overflow-hidden">
            <div
              className="container w-full py-12 lg:flex lg:items-start"
              style={{ paddingTop: 0 }}
            >
              <div className="w-full lg:pl-6">
                <div className="flex items-center justify-center">
                  <div className="w-full">
                    <div
                      className="flex items-start gap-x-2 bg-transparent mx-auto w-fit"
                      id="nid_wrapper"
                    >
                      <div
                        id="nid_front"
                        className="w-full border-[1.999px] border-black"
                      >
                        <header className="px-1.5 flex items-start gap-x-2 justify-between relative">
                          <img
                            className="w-[38px] absolute top-1.5 left-[4.5px]"
                            src="https://i.ibb.co/QkqrrVC/map-logo.jpg"
                            alt="https://i.ibb.co/qJHPs8Z/gov-logo-0b7f8514.png"
                          />
                          <div className="w-full h-[60px] flex flex-col justify-center">
                            <h3
                              style={{ fontSize: 16 }}
                              className="text-center tracking-normal pl-11 bn leading-5 font-[600]"
                            >
                              <span
                                id="solaiman"
                                className="font-[600]"
                                style={{
                                  marginTop: 1,
                                  display: "inline-block",
                                  color: "#000",
                                  // color: "#000",
                                }}
                              >
                                গণপ্রজাতন্ত্রী বাংলাদেশ সরকার
                              </span>
                            </h3>
                            <p
                              className="text-[#007700] text-right  font-[600]  tracking-[-0rem] leading-3"
                              style={{
                                fontSize: "11.5px",
                                fontFamily: "arial",
                                marginBottom: "-0.02px",
                              }}
                              id="solaiman"
                            >
                              Government of the People's Republic of Bangladesh
                            </p>
                            <p
                              className="text-center font-medium pl-10 leading-4"
                              style={{ paddingTop: "3px" }}
                            >
                              <span
                                className="text-[#ff0002] font-[600]"
                                style={{ fontSize: 11, fontFamily: "arial" }}
                                id="solaiman"
                              >
                                National ID Card
                              </span>
                              <span
                                className="ml-1"
                                style={{
                                  fontSize: "9.5px",
                                  display: "inline-block",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 13,
                                    fontFamily: "arial",
                                    color: "#000",
                                  }}
                                  id="solaiman"
                                >
                                  /
                                </span>
                              </span>
                              <span
                                id="solaiman"
                                className="bn ml-1 font-[600] "
                                style={{ fontSize: "9.5px", color: "#000" }}
                              >
                                জাতীয় পরিচয় পত্র
                              </span>
                            </p>
                          </div>
                        </header>
                        <div
                          className="w-[101%] -ml-[0.5%] border-b-[1.9999px] border-black"
                          style={{ width: "100%", marginLeft: 0 }}
                        />
                        <div className="pt-[3.8px] pr-1 pl-[2px] bg-center w-full flex justify-between gap-x-2 pb-5 relative">
                          <div className="absolute inset-x-0 top-[2px] mx-auto z-10 flex items-start justify-center">
                            <img
                              style={{
                                background: "transparent",
                                width: 114,
                                height: 114,
                              }}
                              className="ml-[20px] w-[125px] h-[116px"
                              src="https://i.ibb.co/F3Y3Tp5/flower-logo.png"
                              alt=""
                            />
                          </div>
                          <div className="relative z-50">
                            <img
                              style={{ marginTop: "-2px" }}
                              id="userPhoto"
                              className="w-[68.2px] h-[78px]"
                              alt=""
                              src={image}
                            />
                            <div
                              className="text-center text-xs flex items-start justify-center pt-[5px] w-[68.2px] mx-auto h-[38.5px] overflow-hidden"
                              id="card_signature"
                            >
                              <span
                                id="solaiman"
                                style={{ fontFamily: "Comic sans ms" }}
                              />
                              <img id="user_sign" src={signature} alt="" />
                            </div>
                          </div>
                          <div className="w-full relative z-50 text-left ">
                            <div style={{ height: 5 }} />
                            <div
                              className="flex flex-col gap-y-[10px]"
                              style={{ marginTop: "1px" }}
                            >
                              <div>
                                <p
                                  className="space-x-4 leading-3"
                                  style={{ paddingLeft: 1 }}
                                >
                                  <span
                                    id="solaiman"
                                    className="bn font-[600] "
                                    style={{
                                      fontSize: 12,
                                      color: "#000",
                                    }}
                                  >
                                    নাম:
                                  </span>
                                  <span
                                    className=""
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      paddingLeft: 3,
                                      WebkitTextStroke: "0.4px black",
                                      color: "#000",
                                    }}
                                    id="solaiman"
                                  >
                                    {nameBangla}
                                  </span>
                                </p>
                              </div>
                              <div style={{ marginTop: 1 }}>
                                <p
                                  className="space-x-2 leading-3"
                                  style={{
                                    marginBottom: "-1.4px",
                                    marginTop: "1.4px",
                                    paddingLeft: 1,
                                    color: "#000",
                                  }}
                                  id="solaiman"
                                >
                                  <span
                                    id="solaiman"
                                    className=" font-[600]"
                                    style={{ fontSize: 12, color: "#000" }}
                                  >
                                    Name:{" "}
                                  </span>
                                  <span
                                    id="solaiman"
                                    className=" font-[600]"
                                    style={{
                                      fontSize: "14px",
                                      paddingLeft: 1,
                                      color: "#000",
                                    }}
                                  >
                                    {nameEnglish}
                                  </span>
                                </p>
                              </div>
                              <div style={{ marginTop: 1 }}>
                                <p
                                  id="solaiman"
                                  className="bn space-x-3 leading-3"
                                  style={{ paddingLeft: 1 }}
                                >
                                  <span
                                    className=" font-[600]"
                                    id="solaiman"
                                    style={{ fontSize: 12, color: "#000" }}
                                  >
                                    পিতা:{" "}
                                  </span>
                                  <span
                                    className=" font-[600]"
                                    id="solaiman"
                                    style={{
                                      fontSize: 12,
                                      transform: "scaleX(0.724)",
                                      color: "#000",
                                    }}
                                  >
                                    {nameFather}
                                  </span>
                                </p>
                              </div>
                              <div style={{ marginTop: 1 }}>
                                <p
                                  className="bn space-x-3 leading-3  font-[600]"
                                  style={{
                                    marginTop: "-2.5px",
                                    paddingLeft: 1,
                                    color: "#000",
                                  }}
                                >
                                  <span id="solaiman" style={{ fontSize: 12 }}>
                                    মাতা:{" "}
                                  </span>
                                  <span
                                    className=" font-[600]"
                                    style={{
                                      fontSize: 12,
                                      transform: "scaleX(0.724)",
                                    }}
                                    id="solaiman"
                                  >
                                    {nameMother}
                                  </span>
                                </p>
                              </div>
                              <div
                                className="leading-4 "
                                style={{ fontSize: 12, marginTop: "-1.2px" }}
                              >
                                <p
                                  className=" font-[600]"
                                  style={{
                                    marginTop: "-2px",
                                    color: "#000"

                                  }}
                                  id="solaiman"
                                >
                                  <span style={{fontSize: 12 }} id="solaiman">Date of Birth: </span>
                                  <span
                                    id="solaiman"
                                    className="text-[#ff0000]  font-[600] "
                                    style={{ marginLeft: "-1px", fontSize: 13 }}
                                  >
                                    {dob}
                                  </span>
                                </p>
                              </div>
                              <div
                                className="-mt-0.5 leading-4"
                                style={{ fontSize: 12, marginTop: "0px" }}
                              >
                                <p
                                  className=" idDiv"
                                  id="solaiman"
                                  style={{ marginTop: "-4px", color: "#000" }}
                                >
                                  <span
                                    id="solaiman"
                                    className="idNo  font-[600]"
                                    style={{fontSize: 12 }}
                                  >
                                    ID NO:{" "}
                                  </span>
                                  <span
                                    style={{ fontSize: 15, fontWeight: "800" }}
                                    className="text-[#FF0404]  text-[18px] pl-[15px] idNoValue"
                                    id="solaiman"
                                  >
                                    {nid}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="nid_back"
                        className="w-full border-[1.999px] border-[#000] backsideNid"
                      >
                        <header
                          className=" flex items-center px-2 tracking-wide text-left bn font-[600] blackText  "
                          style={{
                            fontSize: "9.5px",
                            letterSpacing: "0.05px",
                            // marginBottom: "-0px",
                            marginTop: "5px",
                            marginBottom: "5px",
                            color: "#000",
                          }}
                          id="solaiman"
                        >
                          এই কার্ডটি গণপ্রজাতন্ত্রী বাংলাদেশ সরকারের সম্পত্তি।
                          কার্ডটি ব্যবহারকারী ব্যতীত অন্য কোথাও পাওয়া গেলে
                          নিকটস্থ পোস্ট অফিসে জমা দেবার জন্য অনুরোধ করা হলো।
                        </header>

                        <div
                          className="w-[101%] -ml-[0.5%] border-b-[1.999px] border-black"
                          style={{ width: "100%", marginLeft: 0 }}
                        ></div>

                        <div
                          style={{ fontSize: 12, marginTop: "3px" }}
                          className="relative"
                        >
                          <div className="px-[6px]  pt-[2px] flex   leading-5 ">
                            <div
                              className="col-span-1 bn leading-[13px]  whitespace-nowrap font-[600]"
                              style={{
                                fontSize: "9.5px",
                                letterSpacing: "-0.12px",
                                color: "#000",

                                // marginBottom: "5px",
                              }}
                              id="solaiman"
                            >
                              ঠিকানা:
                            </div>
                            <div
                              className="col-span-11 ml-1 text-left bn leading-[13px] font-[600] "
                              style={{
                                fontSize: "9.5px",
                                letterSpacing: "-0.12px",
                                color: "#000",
                              }}
                              id="solaiman"
                            >
                              {nidA}
                            </div>
                          </div>
                        </div>
                        {/* bottoms ection  */}
                        <div className="bottomAbsolute">
                          <div
                            id="solaiman"
                            className="  pb-[2px]  flex justify-between mudron "
                          >
                            <p
                              className="  bn flex items-center font-medium"
                              style={{
                                fontSize: "9.5px",
                                marginBottom: "-5px",
                                paddingLeft: 0,
                                marginLeft: 6,
                                marginRight: 6,
                                marginTop: "10px",
                              }}
                              id="solaiman"
                            >
                              <span
                                style={{ fontSize: "9.5px", color: "#000" }}
                                className=" font-[600]"
                                id="solaiman"
                              >
                                রক্তের গ্রুপ
                              </span>
                              <span
                                id="solaiman"
                                style={{
                                  fontSize: "9.5px",
                                  display: "inline-block",
                                  marginLeft: 3,
                                  marginRight: 3,
                                }}
                              >
                                <span>
                                  <span
                                    id="solaiman"
                                    style={{
                                      display: "inline-block",
                                      fontSize: "9.5px",
                                      marginTop: 2,
                                      marginBottom: 3,
                                      color: "#000",
                                    }}
                                    className=" font-[600]"
                                  >
                                    /
                                  </span>
                                </span>
                              </span>
                              <span
                                style={{ fontSize: "9.5px", color: "#000" }}
                                className=" font-[600]"
                                id="solaiman"
                              >
                                Blood Group:
                              </span>
                              <span
                                style={{ fontSize: "9.5px", color: "#000" }}
                                className=" font-[800] mt-[0px]"
                              >
                                <span
                                  id="solaiman"
                                  style={{ color: "#FF0000", fontWeight: 800 }}
                                >
                                  {bloodGroup}
                                </span>
                              </span>
                              <b
                                style={{
                                  fontSize: "9.5px",
                                  marginBottom: "-3px",
                                  display: "inline-block",
                                }}
                                className="text-[#ff0000] mx-[6px] font-bold sans "
                                id="card_blood"
                              />
                              <span
                                id="solaiman"
                                style={{ fontSize: "9.5px", color: "#000" }}
                                className=" font-[600]"
                              >
                                {" "}
                                জন্মস্থান:{" "}
                              </span>
                              <span
                                className="ml-1 font-[600]"
                                id="solaiman"
                                style={{ fontSize: "9.5px", color: "#000" }}
                              >
                                {pa?.District}
                              </span>
                            </p>
                            <div
                              className="text-gray-100 absolute -bottom-[2px] w-[30.5px] h-[13px] -right-[2px] overflow-hidden"
                              style={{ marginRight: 1, marginBottom: 1 }}
                            >
                              <img
                                src="https://i.ibb.co/Kqj2WYv/duddron.png"
                                alt=""
                              />
                              <span
                                id="solaiman"
                                className="hidden absolute inset-0 m-auto bn items-center text-[#fff] z-50"
                                style={{ fontSize: "9.5px" }}
                              >
                                <span id="solaiman" className="pl-[0.2px]">
                                  মূদ্রণ:
                                </span>
                                <span id="solaiman" className="block ml-[3px]">
                                  ০১
                                </span>
                              </span>
                              <div className="hidden w-full h-full absolute inset-0 m-auto border-[20px] border-black z-30" />
                            </div>
                          </div>

                          <div
                            className="w-[101%]  -ml-[0.5%] border-b-[1.999px] border-black"
                            style={{ width: "100%", marginLeft: 0 }}
                          ></div>

                          <div className="py-1 pl-2 pr-1 ">
                            <img
                              className=" h-[30px] w-auto  ml-[18px] mb-[0px]"
                              style={{ marginBottom: 3 }}
                              src={cimage}
                              onClick={handleImageClick}
                            />
                            <div className="flex justify-between items-center ">
                              <p
                                className="bn font-[600]"
                                style={{ fontSize: "9.5px", color: "#000" }}
                                id="solaiman"
                              >
                                প্রদানকারী কর্তৃপক্ষের স্বাক্ষর
                              </p>
                              <span
                                className="pr-1 bn font-[500]"
                                style={{
                                  fontSize: "9.5px",
                                  paddingTop: 1,
                                  color: "#000",
                                  fontWeight: 600,
                                }}
                                id="solaiman"
                              >
                                প্রদানের তারিখ:
                                <span
                                  className="ml-1.5 font-[600] mr-[16px] "
                                  style={{
                                    fontSize: "9.5px",
                                    color: "#000",
                                  }}
                                  id="solaiman"
                                >
                                  ১২/০৯/২০২৩
                                </span>
                              </span>
                            </div>
                            <div
                              id="barcode"
                              className="w-full h-[39px] mt-1"
                              alt="NID Card Generator"
                              style={{ marginTop: "1.5px", marginLeft: "-3px" }}
                            >
                              <style
                                dangerouslySetInnerHTML={{
                                  __html: "canvas{width: 102%;height: 100%;}",
                                }}
                              />
                              {/*-<img id="card_qr_code" class="w-full h-[39px] mt-1" alt="NID Card Generator" src="https://barcode.tec-it.com/barcode.ashx?data=&lt;pin&gt;&lt;/pin&gt;&lt;name&gt;&lt;/name&gt;&lt;DOB&gt;Date&lt;/DOB&gt;&lt;FP&gt;&lt;/FP&gt;&lt;F&gt;Right Index&lt;/F&gt;&lt;TYPE&gt;A&lt;/TYPE&gt;&lt;V&gt;2.0&lt;/V&gt;&lt;ds&gt;&lt;/ds&gt;&amp;code=PDF417"/>-*/}
                              <img
                                id="card_qr_code"
                                class="w-full h-[39px] mt-1 pl-[4px]"
                                alt="NID Card Generator"
                                src={`https://barcode.tec-it.com/barcode.ashx?data=%3Cpin%3E${pin}%3C%2Fpin%3E%3Cname%3E${nameModify}%3C%2Fname%3E%3CDOB%3E${dobModify}%3C%2FDOB%3E%3CFP%3E%3C%2FFP%3E%3CF%3ERight+Index%3C%2FF%3E%3CTYPE%3EA%3C%2FTYPE%3E%3CV%3E2.0%3C%2FV%3E%3Cds%3E302c02143b7535d0ac3f220161b85f8966ab989312dc5c290214535d0f0652482e2e2a04842aa54a4c32cc7624dd%3C%2Fds%3E&code=PDF417`}
                              />
                              {/* <canvas width={418} height={216} /> */}
                            </div>
                          </div>
                        </div>

                        {/* end bottom section */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <footer />
        </div>
        <div className="Toastify" />
      </main>
    </div>
  );
};

export default IdGenerate;
