import { createContext, useContext, useEffect, useState } from "react";

const MyContext = createContext(undefined);
export function useMyContext() {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error("useMyContext must be used within MyContextProvider");
  }
  return context;
}

// Create a provider component to wrap your app
export function MyContextProvider({ children }) {

  const [user, setUser] = useState();
  const [text, setText] = useState(null);
  const [image, setImage] = useState(null);
  const [signature, setSignature] = useState(null);
  const [fullNidData, setFullNidData] = useState(null);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [permanentAddress, setPermanentAddress] = useState(null);
  // window.location.reload();
  // name bangla
  const [nameBangla, setNameBangla] = useState("");
  // name english
  const [nameEnglish, setNameEnglish] = useState("");
  // nid number
  const [nid, setNid] = useState(null);
  // pin number
  const [pin, setPin] = useState(null);
  // father name
  const [nameFather, setNameFather] = useState(null);
  // mother name
  const [nameMother, setNameMother] = useState(null);
  // Birth Place
  const [birthPlace, setBirthPlace] = useState(null);
  // date of birth
  const [dob, setDob] = useState(null);
  // home holding number
  const [homeHolding, setHomeHolding] = useState("");
  // road
  const [road, setRoad] = useState(null);
  const [postOffice, setPostOffice] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [upozilla, setUpozilla] = useState(null);
  const [city, setCity] = useState(null);
  const [fulladdress, setFulladdress] = useState(null);
  const [bloodGroup, setBloodGroup] = useState(null);
  const [imgDownloaded, setImgDownloaded] = useState(null);
  const [imgDownloadedS, setImgDownloadedS] = useState(null);
  const [voterNumber, setVoterNumber] = useState(null);
  const [formNumber, setFormNumber] = useState(null);
  const [spouseName, setSpouseName] = useState(null);
  const [pa, setPa]=useState({})
  const [ca, setCa]=useState({})
  const [ gender, setGender]=useState()
  const [edu, setEdu]=useState()
  const [ serverA, setServerA]=useState()
  const [nidA , setNidA]=useState()
  const [ serverPresent, setServerPresent]=useState()
  const [mob, setMob]=useState()
  const [religion, setReligion]=useState()
  const [occupation, setOccupation]=useState()
  const [religionOther, setReligionOther]=useState()

  const contextValue = {
    mob, setMob,
    religion, setReligion,
    religionOther, setReligionOther,
    occupation, setOccupation, 
    edu, setEdu,
    pa, setPa,
    ca, setCa,
    imgDownloaded,
    setImgDownloaded,
    imgDownloadedS,
    setImgDownloadedS,
    user,
    setUser,
    text,setText,
    image, setImage,
    signature, setSignature,
    nameBangla,setNameBangla,
    nameEnglish, setNameEnglish,
    nid ,setNid,
    pin  ,setPin,
    nameFather,setNameFather,
    nameMother,setNameMother,
    birthPlace,setBirthPlace,
    dob,setDob,
    homeHolding, setHomeHolding,
    road, setRoad,
    postOffice, setPostOffice,
    postalCode,setPostalCode,
    upozilla, setUpozilla,
    city, setCity,
    fulladdress, setFulladdress,
    bloodGroup, setBloodGroup,
    fullNidData, setFullNidData,
    currentAddress, setCurrentAddress,
    permanentAddress, setPermanentAddress,
    voterNumber, setVoterNumber,
    formNumber, setFormNumber,
    spouseName, setSpouseName,
    gender, setGender,
    serverA, setServerA,
    nidA , setNidA,
    serverPresent,
    setServerPresent
  };

  useEffect(()=>{
    const userFromLS=JSON.parse(localStorage.getItem("user"))
    setUser(userFromLS)
  },[])


  return (
    <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>
  );
}
