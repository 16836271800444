import React, { useEffect, useState, Fragment, useRef } from "react";
import { useMyContext } from "../../app.context";
import { getAllUser, updateUser, deleteUser, createUser } from "../shared/api";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { ToastError, ToastSuccess } from "../shared/SmallComponents";

const UserList = () => {
  const { user, setUser } = useMyContext();
  const [users, setUsers] = useState([]);
  const [cardsRefresh, setCardRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        const email = {
          email: user.email,
        };
        try {
          const userRes = await getAllUser(email);
          setUsers(userRes);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, [user, cardsRefresh]);
  // console.log("object");


  const makeEnabled = async (id) => {
    const body = {
      workPermit: true,
      email: user.email,
    };
    const updateRes = await updateUser(id, body);
    setCardRefresh(!cardsRefresh);
  };
  const makeDisabled = async (id) => {
    const body = {
      workPermit: false,
      email: user.email,
    };
    const updateRes = await updateUser(id, body);
    setCardRefresh(!cardsRefresh);
  };
  const handleAdmin = async (id) => {
    const body = {
     permission:"user",
     email: user.email,
    };
    const updateRes = await updateUser(id, body);
    setCardRefresh(!cardsRefresh);
  };
  const handleUser = async (id) => {
    const body = {
     permission:"admin",
     email: user.email, 
    };
    const updateRes = await updateUser(id, body);
    setCardRefresh(!cardsRefresh);
  };

  const deleteUserhandle = async (id) => {
    const body = {
      email: user.email,
    };
    const deleteRes = await deleteUser(id, body);
    setCardRefresh(!cardsRefresh);
  };
  const handleCreate = async (e) => {
    e.preventDefault();
    const t = e.target;
    const body = {
      name:t.name.value,
      email:t.email.value,
      adminEmail:user.email,
      password: t.password.value,
      phone: t.phone.value,
    };
    const res = await createUser(body);
    console.log("data ", body);
    if(res?.error==false){
      ToastSuccess(res?.message || "New account created")
      setOpen(false)
    }
    else {
      ToastError(res?.message || "Something wrong")
    }
    setCardRefresh(!cardsRefresh);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <form
                        action=""
                        style={{ width: "300px " }}
                        onSubmit={handleCreate}
                      >
                        <h2 className="text-2xl">Create new account</h2>
                        <div className="my-2">
                          <label htmlFor="">Name</label> <br />
                          <input
                            type="text"
                            name="name"
                            id=""
                            className=" rounded-md px-1 py-1 w-full "
                            style={{ backgroundColor: "#E7E7FF " }}
                          />
                        </div>
                        <div className="my-2">
                          <label htmlFor="">Email</label> <br />
                          <input
                            type="email"
                            name="email"
                            id=""
                            className=" rounded-md px-1 py-1 w-full "
                            style={{ backgroundColor: "#E7E7FF " }}
                          />
                        </div>
                        <div className="my-2">
                          <label htmlFor="">Password</label> <br />
                          <input
                            type="password"
                            name="password"
                            id=""
                            className=" rounded-md px-1 py-1 w-full "
                            style={{ backgroundColor: "#E7E7FF " }}
                          />
                        </div>
                        <div className="my-2">
                          <label htmlFor="">Phone No</label> <br />
                          <input
                            type="tel"
                            name="phone"
                            id=""
                            className=" rounded-md px-1 py-1 w-full "
                            style={{ backgroundColor: "#E7E7FF " }}
                          />
                        </div>
                        <div className="bg-gray-50  py-3 sm:flex sm:flex-row-reverse sm:px-6">
                          <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => setOpen(false)}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="ts-main-content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h2
                  className="page-title text-2xl "
                  style={{
                    textAlign: "start !important",
                  }}
                >
                  USERS
                </h2>
                <button
                  className="mb-3 mt-2 text-blue-500 "
                  onClick={() => setOpen(true)}
                >
                  Create new user
                </button>
                {/* Zero Configuration Table */}
                <div className="panel panel-default">
                  <div className="panel-heading">List of Users</div>
                  <div className="panel-body">
                    <table
                      id="zctb"
                      className="display table table-striped table-bordered table-hover"
                      cellSpacing={0}
                      width="100%"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Role</th>
                          <th>Manage Role</th>
                          <th>Work Permit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users?.map((card, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              {/* <td><img src="../images/" style="width:50px; border-radius:50%;" /></td> */}
                              <td>{card?.name}</td>
                              <td>{card?.email}</td>
                              <td>{card?.phone}</td>
                              <td>{card?.permission}</td>
                              <td>{card?.permission==="admin" ?
                              <button onClick={()=>handleAdmin(card._id)} style={{color:"red"}}>Make user</button> :
                              <button onClick={()=>handleUser(card._id)} style={{color:"green"}}>Make Admin</button>}</td>
                              <td>
                                {card?.workPermit ? (
                                  <button
                                    onClick={() => makeDisabled(card._id)}
                                  >
                                    <i
                                      class="fa-solid fa-check"
                                      style={{ color: "green" }}
                                    ></i>
                                  </button>
                                ) : (
                                  <button onClick={() => makeEnabled(card._id)}>
                                    <i
                                      class="fa-solid fa-ban"
                                      style={{ color: "red" }}
                                    ></i>
                                  </button>
                                )}
                              </td>
                              <td>
                                {" "}
                                <button
                                  onClick={() => deleteUserhandle(card._id)}
                                >
                                  <i
                                    className="fa fa-trash"
                                    style={{ color: "red", fontSize: "0.8em" }}
                                    aria-hidden="true"
                                  />
                                </button>
                              </td>
                              {/* </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;
