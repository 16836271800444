import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import { base, loginUser } from "../shared/api";
import { useMyContext } from "../../app.context";
import axios from "axios";
const Login = () => {

  const { user, setUser } = useMyContext();

  useEffect(()=>{
axios.get(`${base}/id/all-data-free`)
.then(data=>{
  console.log("data id", data);
})
.catch(err=>{
  console.log("err id", err);
})
  },[])
  const navigate=useNavigate()
  const handleLogin = async (e) => {
    e.preventDefault();
    const t = e.target;
    const body = {
      email: t.email.value,
      password: t.password.value,
    };
    const userRes = await loginUser(body);
    // localStorage.setItem("token", userRes.token)
    if(userRes?.user){
      localStorage.setItem("user", JSON.stringify(userRes?.user))
      setUser(userRes?.user)
      navigate("/")
    }

  };

  return (
    <div className="body_flex">
      <div className="login_form_container">
        <div className="login_form">
          <h2>Login</h2>
          <form action="" onSubmit={handleLogin}>
            <div className="input_group">
              <i className="fa fa-user"></i>
              <input
                type="email"
                placeholder="Email"
                name="email"
                className="input_text"
                autoComplete="true"
                required
              />
            </div>
            <div className="input_group">
              <i className="fa fa-unlock-alt"></i>
              <input
                type="password"
                placeholder="Password"
                name="password"
                className="input_text"
                autoComplete="true"
                required
              />
            </div>
            <div className="button_group" id="login_button">
              <button value="login" type="submit">
                Login
              </button>
            </div>
          </form>
          {/* <div className="fotter">
            <Link to="/register">Sign Up</Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
