import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Loading from "./Loading";
import axios from "axios";
import { base } from "./api";

const VerifyUser = ({ children }) => {
  // const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    // console.log("user email", user?.email);
    // const email=user?.email

    if (user?.email && token) {
      axios
        .post(
          `${base}/user/verify/${user?.email}`,
          {
            // token: `Bearer ${token}`,
          },
          {
            headers: {
              "content-type": "application/json",
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // console.log("res", res);
          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          navigate("/login");
          setLoading(false);
        });
    } else {
      setLoading(false);
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      navigate("/login");
      // return <Navigate to="/login" state={{ from: location }} replace></Navigate>
    }
  }, [navigate]);

  if (loading) {
    return <Loading></Loading>;
  }

  return children;
};

export default VerifyUser;
