import React, { useState } from "react";
import { useMyContext } from "./app.context";

const Testing = () => {
  const {
    user,
    text,
    image,
    signature,
    nameBangla,
    nameEnglish,
    nid,
    pin,
    nameFather,
    nameMother,
    birthPlace,
    dob,
    homeHolding,
    road,
    postOffice,
    postalCode,
    upozilla,
    city,
    fulladdress,
    bloodGroup,
    imgDownloaded,
    imgDownloadedS
  } = useMyContext();
  console.log("name", nameEnglish,dob);

  const [da, setDa]=useState("মৃ")
  const [da2, setDa2]=useState("ত")
  const sttt="মৃ"

  const teting=()=>{
    const data={
      name:"djfkd",
      email:"dkjfkdf"
    }
    fetch("https://api.priyoshop.xyz/makeNid", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(res=>res?.JSON())
    .then(data=>{
      console.log("data is c", data);
    })
    .catch(err=>{
      console.log("eer", err );
    })
  }
  return (
    <div>

      <button onClick={teting}>kdjfkdjfklj</button> <br />
      <button onClick={teting}>{da}{da2}</button>

      <div
        style={{
          width: "100vw",
          textAlign: "center",
          marginTop: "100px",
          marginLeft: "100px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          {/* insert your custom barcode setting your data in the GET parameter "data" */}
          <img
            alt="Barcode Generator TEC-IT"
            src={`https://barcode.tec-it.com/barcode.ashx?data=%3Cpin%3E${pin}%3C%2Fpin%3E%3Cname%3E${nameEnglish}%3C%2Fname%3E%3CDOB%3E15+Jan+2001%3C%2FDOB%3E%3CFP%3E%3C%2FFP%3E%3CF%3ERight+Index%3C%2FF%3E%3CTYPE%3EA%3C%2FTYPE%3E%3CV%3E2.0%3C%2FV%3E%3Cds%3E302c02143b7535d0ac3f220161b85f8966ab989312dc5c290214535d0f0652482e2e2a04842aa54a4c32cc7624dd%3C%2Fds%3E&code=PDF417`}
          />


  <img alt='Barcode Generator TEC-IT'
      //  src='https://barcode.tec-it.com/barcode.ashx?data=This+is+a+QR+Code+by+TEC-IT&code=QRCode&dataattributekey_2=&dataattributeval_2=&dataattributekey_3=&dataattributeval_3=&dataattributekey_4=&dataattributeval_4=&dataattributekey_5=&dataattributeval_5=&digitallink=&eclevel=L&dmsize=Default'/>

          src={`https://barcode.tec-it.com/barcode.ashx?data=%3Cpin%3E${pin}%3C%2Fpin%3E%3Cname%3E${nameEnglish} %3C%2Fname%3E%3CDOB%3E15+Jan+2001%3C%2FDOB%3E%3CFP%3E%3C%2FFP%3E%3CF%3ERight+Index%3C%2FF%3E%3CTYPE%3EA%3C%2FTYPE%3E%3CV%3E2.0%3C%2FV%3E%3Cds%3E302c02143b7535d0ac3f220161b85f8966ab989312dc5c290214535d0f0652482e2e2a04842aa54a4c32cc7624dd%3C%2Fds%3E&code=PDF417`}
          // src="https://barcode.tec-it.com/barcode.ashx?data=%3Cpin%3E20019314714000343%3C%2Fpin%3E%3Cname%3EMEHEDI+HASAN%3C%2Fname%3E%3CDOB%3E15+Jan+2001%3C%2FDOB%3E%3CFP%3E%3C%2FFP%3E%3CF%3ERight+Index%3C%2FF%3E%3CTYPE%3EA%3C%2FTYPE%3E%3CV%3E2.0%3C%2FV%3E%3Cds%3E302c02143b7535d0ac3f220161b85f8966ab989312dc5c290214535d0f0652482e2e2a04842aa54a4c32cc7624dd%3C%2Fds%3E&code=PDF417"
    />
        </div>
      </div>
      <img alt='Barcode Generator TEC-IT'
       src='https://barcode.tec-it.com/barcode.ashx?data=This+is+a+QR+Code+by+TEC-IT&code=QRCode&dataattributekey_2=&dataattributeval_2=&dataattributekey_3=&dataattributeval_3=&dataattributekey_4=&dataattributeval_4=&dataattributekey_5=&dataattributeval_5=&digitallink=&eclevel=L&dmsize=Default'/>
    </div>
  );
};

export default Testing; 
