import React from 'react';

const Profile = () => {
  const user=JSON.parse(localStorage.getItem("user"))
  console.log("user", user );
    return (
        <div className="ts-main-content">
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel panel-default">
                      <div className="panel-heading">
                       {user?.email}
                      </div>
                      <div className="panel-body">
                        <form
                          method="post"
                          className="form-horizontal"
                          encType="multipart/form-data"
                        >
                          <div className="form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-4 text-center">
                              <img
                                src={user?.image || `https://ui-avatars.com/api/?name=${user?.name}&background=0D8ABC&color=fff`}


                                style={{
                                  width: 200,
                                  borderRadius: "50%",
                                  margin: 10
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Name
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="col-sm-4">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                required=""
                                defaultValue={user?.name}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Email
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="col-sm-4">
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                required=""
                                readOnly
                                defaultValue={user?.email}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Mobile
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="col-sm-4">
                              <input
                                type="text"
                                name="mobile"
                                className="form-control"
                                required=""
                                defaultValue={user?.phone}
                              />
                            </div>
                          </div>
                          <input
                            type="hidden"
                            name="editid"
                            className="form-control"
                            required=""
                            defaultValue={54}
                          />
                          <div className="form-group">
                            <div className="col-sm-8 col-sm-offset-2">
                              <button
                                className="btn btn-primary"
                                name="submit"
                                type="submit"
                                style={{color:"#696CFF"  }}
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Profile;
